/**
 * Lists the settlement with the given parameters with pagination.
 * @param payload the payload to set
 * @returns the response of the request.
 */
async function list(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/settlement/list?page=${payload.page}&count=${payload.count}&activated=${payload.activated}&keyword=${payload.keyword}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`
                }
            });
            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    const errorResponse = await response.json();
                    return reject(errorResponse);
                }
            }

            const pageCount = response.headers.get('X-Page-Count');

            const data = await response.json();
            resolve({ headers: { pageCount }, data: data.data});
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

export default {
    list
};