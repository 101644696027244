interface AuthProvider {
    isAuthenticated(): boolean;
    signin(username: string): Promise<void>;
    signout(): Promise<void>;
}

/**
 * This represents some generic auth provider API, like Firebase.
 */
export const authProvider: AuthProvider = {
    async signin(email: string) {
        await new Promise((r) => setTimeout(r, 500));
        localStorage.setItem("SESSION_KEY", "ADMIN_USER_SESSION_KEY");
    },
    async signout() {
        localStorage.removeItem("SESSION_KEY");
    },
    isAuthenticated() {
        return localStorage.getItem('SESSION_KEY') ? true : false;
    }
};