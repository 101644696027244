import { useRef, useState } from 'react';
import './send-message.styles.css';
import { toast } from 'react-toastify';

import AddMessageSenderDialog from '../../../../components/add-message-sender/add-message-sender.dialog';
import DefaultUserIcon from '../../../../assets/default-user-icon.jpg';
import SelectSettlementDialog from '../../../../components/select-settlement/select-settlement.dialog';

import COUNTIES from '../../../../constants/counties';
import SelectIslandDialog from '../../../../components/select-island/select-island.dialog';
import XMarkIcon from '../../../../icons/xmark.icon';

import MessageService from '../../../../services/message.service';
import { authProvider } from '../../../../auth.provider';
import { useNavigate } from 'react-router-dom';
import CropImageDialog from '../../../../components/crop-image/crop-image.dialog';
import MessagePreviewDialog from './components/message-preview.page';

function SendMessagePage() {

    const navigate = useNavigate();

    const imageInputRef = useRef<any>(null);

    const [messageType, setMessageType] = useState<string>('post');
    const [messageTitle, setMessageTitle] = useState<string>('');
    const [messageBody, setMessageBody] = useState<string>('');
    const [messageTags, setMessageTags] = useState<string>('');
    const [sender, setSender] = useState<any>(null);
    const [images, setImages] = useState<Array<any>>([]);
    const [recipientControllerType, setRecipientControllerType] = useState<string>('all');
    const [recipients, setRecipients] = useState<Set<string>>(new Set<string>());
    const [recipientToAdd, setRecipientToAdd] = useState<string>('all');
    const [priority, setPriority] = useState<number>(2);
    const [videoURL, setVideoURL] = useState<string>('');
    const [pollQuestion, setPollQuestion] = useState<string>('');
    const [pollAnswers, setPollAnswers] = useState<Set<string>>(new Set<string>());
    const [pollAnswerToAdd, setPollAnswerToAdd] = useState<string>('');
    const [messageMediaType, setMessageMediaType] = useState<string>('images');

    const [senderSelectOpened, setSenderSelectOpened] = useState<boolean>(false);
    const [settlementSelectOpened, setSettlementSelectOpened] = useState<boolean>(false);
    const [islandSelectOpened, setIslandSelectOpened] = useState<boolean>(false);

    const [messageSending, setMessageSending] = useState<boolean>(false);

    const [showImageCropper, setShowImageCropper] = useState<boolean>(false);
    const [imagePreviews, setImagePreviews] = useState<Array<any>>([]);
    const [imageToCrop, setImageToCrop] = useState<any>(null);

    const [showMessagePreview, setShowMessagePreview] = useState<boolean>(false);
    const [messagePreviewPayload, setMessagePreviewPayload] = useState<any>(null);

    /**
     * Opens the dialog where the user can select
     * a sender.
     */
    function openSenderSelectDialog(): void {
        setSenderSelectOpened(true);
    }

    /**
     * Closes the dialog and process the incoming data.
     * @param sender the result of the dialog request close
     */
    function handleSenderSelectDialogClose(sender: any) {
        setSenderSelectOpened(false);
        if (sender) {
            setSender(sender);
        }
    }

    /**
     * Opens the file browser to add an image.
     */
    function addImage(): void {
        if (imageInputRef.current) {
            imageInputRef.current.click();
        }
    }

    /**
     * Handles the image load into the island logo.
     * @param _event the file browser close event
     */
    async function handleImageAdd(_event: any): Promise<void> {
        const file = _event.target.files[0];
        imageInputRef.current.value = '';

        if (!file) {
            return;
        }

        // Load the image from the file source
        const loadedImage = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result));
            reader.readAsDataURL(file);
        });

        setImageToCrop(loadedImage);
        setShowImageCropper(true);
    }

    /**
     * Called when the image cropper dialog closed.
     * @param image the image returned by the cropper dialog.
     */
    async function onImageCropped(image: any): Promise<void> {
        setImageToCrop(null);
        setShowImageCropper(false);

        if (image) {
            const imageFile = new File([image], new Date().getTime().toString(), { type: image.type });

            // Convert the blob to display image format (base64)
            const previewImageData = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.addEventListener('load', () => resolve(reader.result));
                reader.readAsDataURL(image);
            });

            setImages([...images, imageFile]);
            setImagePreviews([...imagePreviews, { name: imageFile.name, data: previewImageData }]);
        }
    }

    /**
     * Handles the type change of the recipient controller.
     * @param type the type of the recipient control
     */
    function handleRecipientControllerTypeChanged(type: string): void {
        setRecipientControllerType(type);
        switch (type) {
            case 'all':
                setRecipientToAdd('all');
                break;
            case 'settlement':
                setRecipientToAdd('');
                break;
            case 'county':
                setRecipientToAdd('county:' + COUNTIES[0]);
                break;
        }
    }

    /**
     * Adds the recipient to the recipient list.
     */
    function addRecipient(): void {
        if (recipientToAdd) {
            setRecipients(new Set(Array.from(recipients).concat(recipientToAdd)));
            if (recipientControllerType != 'all' && recipientControllerType != 'county') {
                setRecipientToAdd('');
            }
        }
    }

    /**
     * Returns the recipient display name from the recipient item.
     * @param recipient the recipient from the recipients array
     * @returns The recipient display name
     */
    function getRecipientDisplayName(recipient: string): string {
        switch (recipient.split(':')[0]) {
            case 'all':
                return 'Minden sziget';
            case 'settlement':
                return 'Település: ' + recipient.split(':')[1];
            case 'county':
                return 'Megye: ' + recipient.split(':')[1];
            case 'island':
                return 'Sziget: ' + recipient.split(':')[2];
            default:
                return '';
        }
    }

    /**
     * Handles all of the recipients select closes.
     * @param type the type of the select
     * @param result the result of the select
     */
    function handleRecipientSelectClose(type: string, result: any): void {
        setSettlementSelectOpened(false);
        setIslandSelectOpened(false);
        if (result) {
            if (type == 'settlement') {
                setRecipientToAdd('settlement:' + result);
            }
            if (type == 'island') {
                setRecipientToAdd(`island:${result._id}:${result.name}`);
            }
        }
    }

    /**
     * Removes the given recipient from the recipient list.
     * @param recipient the recipient to remove
     */
    function removeRecipient(recipient: string): void {
        const updatedRecipients = new Set(recipients);
        updatedRecipients.delete(recipient);
        setRecipients(updatedRecipients);
    }

    /**
     * Converts the youtube video to embeded link.
     * @param url the url of the youtube video
     */
    function attachVideo(url: string): void {
        const youtubeRegExp = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/|.+\?v=)?([a-zA-Z0-9_-]{11})(\S*)?$/;
        if (url && url !== '' && youtubeRegExp.test(url)) {
            const params = url.split('?')[1].split('&');
            params.forEach((param: string) => {
                if (param.includes('v=')) {
                    setVideoURL('https://youtube.com/embed/' + param.slice(2));
                }
            });
        } else {
            setVideoURL('');
        }
    }

    /**
     * Adds the answer to the poll answers list.
     */
    function addPollAnswer(): void {
        if (pollAnswerToAdd) {
            setPollAnswers(new Set(Array.from(pollAnswers).concat(pollAnswerToAdd)));
            setPollAnswerToAdd('');
        }
    }

    /**
     * Removes the poll answers from the list.
     * @param answer the answer to remove
     */
    function removePollAnswer(answer: string): void {
        const updatedAnswers = new Set(pollAnswers);
        updatedAnswers.delete(answer);
        setPollAnswers(updatedAnswers);
    }

    /**
     * Sends the message to the server.
     */
    function sendMessage(): void {
        setMessageSending(true);

        // Validate fields and construct the payload
        if (!validateMessageFields()) {
            setMessageSending(false);
            return;
        }
        const payload = getMessagePayload();

        // Send the request to the API
        MessageService.create(payload)
            .then(() => {
                setMessageSending(false);
                resetForm();
                toast.info('Üzenet elküldve!');
            })
            .catch((err) => {
                setMessageSending(false);
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    console.log('Fire');
                    toast.error(err.status);
                    return;
                }
            });
    }

    /**
     * Validates the fileds of the message.
     */
    function validateMessageFields(): boolean {
        if (!sender) {
            toast.error('Nincs megadva a feladó!');
            return false;
        }

        if (!messageTitle || messageTitle == '') {
            toast.error('A cím megadása kötelező!');
            return false;
        }

        if (!messageBody || messageBody == '') {
            toast.error('A törzs megadása kötelező!');
            return false;
        }

        if (recipients.size < 1) {
            toast.error('Legalább egy címzettnek lennie kell!');
            return false;
        }

        if (messageType == 'poll' && (!pollQuestion || pollQuestion == '')) {
            toast.error('Ha az üzenet szavazás a kérdést be kell állítani!');
            return false;
        }

        if (messageType == 'poll' && pollAnswers.size < 2) {
            toast.error('Ha az üzenet szavazás minimum két válaszlehetőséget meg kell adni!');
            return false;
        }

        const specialCharsRegex = /[!@$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]/;
        if (specialCharsRegex.test(messageTags)) {
            toast.error('Az üzenet címkéi nem tartalmazhatnak speciális karaktereket!');
            return false;
        }

        let tags = messageTags.split(' ').map((tag: string) => tag.toLowerCase().replace('#', ''));
        tags = tags.filter((tag: string) => tag != '');
        if (Array.from(new Set(tags)).length < 1) {
            toast.error('Az üzenetnek legalább egy címkét tartalmaznia kell!');
            return false;
        }

        return true;
    }

    /**
     * Returns the message payload which will be haded
     * over to the API request handler function.
     */
    function getMessagePayload(): any {
        let tags = messageTags.split(' ').map((tag: string) => tag.toLowerCase().replace('#', ''));
        tags = tags.filter((tag: string) => tag != '');

        // Construct the message API payload
        const payload = {
            type: messageType,
            sender: sender._id,
            title: messageTitle,
            body: messageBody,
            recipients: Array.from(recipients),
            priority: priority,
            videoURL: videoURL,
            tags: Array.from(new Set(tags)),
            poll: pollQuestion && pollAnswers.size > 0 ? { question: pollQuestion, answers: Array.from(pollAnswers) } : null,
            images: images,

            // Preview fields
            preview: {
                sender: {
                    name: sender.name,
                    avatar: sender.avatar
                },
                thumbnail: imagePreviews.length > 0 ? imagePreviews[0] : null,
                images: imagePreviews
            }
            
        }

        return payload;
    }

    /**
     * Removes the given image from the images list.
     * @param imageFile the image file
     */
    function removeImage(imageFileName: string): void {
        const filteredImages = images.filter((image: any) => image.name != imageFileName);
        const filteredImagePreviews = imagePreviews.filter((image: any) => image.name != imageFileName);
        setImages(filteredImages);
        setImagePreviews(filteredImagePreviews);
    }

    /**
     * Reset the form.
     */
    function resetForm(): void {
        setMessageType('post');
        setMessageTitle('');
        setMessageBody('');
        setImagePreviews([]);
        setImages([]);
        setPollAnswerToAdd('');
        setPollAnswers(new Set());
        setPollQuestion('');
        setSender(null);
        setPriority(2);
        setVideoURL('');
        setRecipientToAdd('all');
        setRecipients(new Set());
        setMessageTags('');
        setRecipientControllerType('all');
    }

    /**
     * Sets up the payload for the message preview
     * and shows the message preview panel if there are no errors.s
     */
    function setUpAndShowMessagePreview(): void {
        if (!validateMessageFields()) {
            return;
        }
        const payload = getMessagePayload();
        setMessagePreviewPayload(payload);
        setShowMessagePreview(true);
    }

    return <div className='subpage-window fade-in-up'>
        {senderSelectOpened && <AddMessageSenderDialog onClose={handleSenderSelectDialogClose} />}
        {settlementSelectOpened && <SelectSettlementDialog onClose={(result: any) => handleRecipientSelectClose('settlement', result)} />}
        {islandSelectOpened && <SelectIslandDialog onClose={(result: any) => handleRecipientSelectClose('island', result)} />}
        {showImageCropper && <CropImageDialog image={imageToCrop} aspect={16 / 9} onClose={onImageCropped} />}
        {showMessagePreview && <MessagePreviewDialog onClose={() => setShowMessagePreview(false)} message={messagePreviewPayload} />}

        <h2 className="subpage-title">Üzenet küldése</h2>

        {!showMessagePreview && <div id='send-message-form'>
            <div>
                <label className='form-item-label'>Feladó</label>
                {!sender && <p className='send-message-no-data'>Nincs kiválasztva még a feladó!</p>}
                {sender && <div className='send-message-sender-container minimum-shadow'>
                    {sender.avatar == 'default' && <img src={DefaultUserIcon} className='send-message-sender-image minimum-shadow' />}
                    {sender.avatar != 'default' && <img src={sender.avatar} className='send-message-sender-image minimum-shadow' />}
                    <h3 className='send-message-sender-name'>{sender.name}</h3>
                </div>}
                <button onClick={openSenderSelectDialog}>Kiválasztás</button>
            </div>

            <div>
                <label className='form-item-label'>Prioritása</label>
                <div className='radio-group'>
                    <div className='radio-group-item'>
                        <label className='radio-group-item-label'>
                            <input value='2' checked={priority == 2} onChange={(_event: any) => setPriority(Number(_event.target.value))} className='radio-group-item-button' type='radio' name='priority'></input>
                            Normál
                        </label>
                    </div>
                    <div className='radio-group-item'>
                        <label className='radio-group-item-label'>
                            <input value='1' checked={priority == 1} onChange={(_event: any) => setPriority(Number(_event.target.value))} className='radio-group-item-button' type='radio' name='priority'></input>
                            Fontos
                        </label>
                    </div>
                </div>
            </div>

            <div>
                <label className='form-item-label'>Típusa</label>
                <div className='radio-group'>
                    <div className='radio-group-item'>
                        <label className='radio-group-item-label'>
                            <input value='post' checked={messageType == 'post'} onChange={(_event: any) => { setMessageType(_event.target.value); setPollAnswers(new Set()); setPollQuestion('') }} className='radio-group-item-button' type='radio' name='message-type'></input>
                            Egyszerű
                        </label>
                    </div>
                    <div className='radio-group-item'>
                        <label className='radio-group-item-label'>
                            <input value='poll' checked={messageType == 'poll'} onChange={(_event: any) => setMessageType(_event.target.value)} className='radio-group-item-button' type='radio' name='message-type'></input>
                            Szavazás
                        </label>
                    </div>
                </div>
            </div>

            <div>
                <label className='form-item-label'>Cím</label>
                <input value={messageTitle} onChange={(_event: any) => setMessageTitle(_event.target.value)} placeholder='Az üzenet címe' className='send-message-input' />
            </div>

            <div>
                <label className='form-item-label'>Törzs</label>
                <textarea value={messageBody} onChange={(_event: any) => setMessageBody(_event.target.value)} className='send-message-input' placeholder='Az üzenet leírása'></textarea>
            </div>

            <div>
                <label className='form-item-label'>Címkék</label>
                <input value={messageTags} onChange={(_event: any) => setMessageTags(_event.target.value)} placeholder='Budapest Összefogás TiszaPárt' className='send-message-input' />
            </div>

            {messageType == 'poll' && <div>
                <label className='form-item-label'>Szavazás beállításai</label>
                <input onChange={(_event: any) => setPollQuestion(_event.target.value)} style={{ width: 400 }} placeholder='Kérdés' />
                <div className='send-message-poll-answer-controller'>
                    <input value={pollAnswerToAdd} onChange={(_event: any) => setPollAnswerToAdd(_event.target.value)} style={{ width: 300 }} placeholder='Válaszlehetőség'></input>
                    <button onClick={addPollAnswer}>Hozzáadás</button>
                </div>
                {pollAnswers.size < 1 && <p className='send-message-no-data'>Még nem adtál hozzá egyetlen választ sem.</p>}
                <ol>
                    {Array.from(pollAnswers).map((answer: string) => (
                        <li key={answer} className='send-message-answer-list-item'>
                            {answer}
                            <XMarkIcon onClick={() => removePollAnswer(answer)} className='send-message-answer-list-item-remove remove-item-xmark' />
                        </li>
                    ))}
                </ol>
            </div>}

            <div>
                <label className='form-item-label'>Média típusa</label>
                <div className='radio-group'>
                    <div className='radio-group-item'>
                        <label className='radio-group-item-label'>
                            <input value='images' checked={messageMediaType == 'images'} onChange={(_event: any) => { setMessageMediaType(_event.target.value); setVideoURL(''); }} className='radio-group-item-button' type='radio' name='message-media-type'></input>
                            Fényképek
                        </label>
                    </div>
                    <div className='radio-group-item'>
                        <label className='radio-group-item-label'>
                            <input value='video' checked={messageMediaType == 'video'} onChange={(_event: any) => { setMessageMediaType(_event.target.value); setImages([]); setImagePreviews([]); }} className='radio-group-item-button' type='radio' name='message-media-type'></input>
                            Videó
                        </label>
                    </div>
                </div>

                {messageMediaType == 'images' && <div>
                    {imagePreviews.length < 1 && <p className='send-message-no-data'>Nincsenek csatolt fényképek!</p>}
                    {imagePreviews.length > 0 && <div className='send-message-attached-images'>
                        {imagePreviews.map((imagePreview: any) => (
                            <div key={imagePreview.name} style={{ position: 'relative' }}>
                                <img className='send-message-image-preview minimum-shadow' src={imagePreview.data} />
                                <XMarkIcon onClick={() => removeImage(imagePreview.name)} className='remove-item-xmark remove-image-button' />
                            </div>
                        ))}
                    </div>}
                    <input onChange={(_event: any) => handleImageAdd(_event)} type="file" ref={imageInputRef} id="island-upload-logo" hidden />
                    <button onClick={addImage}>Fénykép csatolása</button>
                </div>}

                {messageMediaType == 'video' && <div>
                    <input value={videoURL} onChange={(_event: any) => attachVideo(_event.target.value)} placeholder='YouTube videó link' style={{ minWidth: 400, marginTop: 16 }} />
                    {videoURL != '' && <iframe className='send-message-video-thumbnail minimum-shadow' width="300" height="200" src={videoURL} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>}
                </div>}
            </div>

            <div>
                <label className='form-item-label'>Címzett</label>
                <div id='send-message-recipients-controller'>
                    <select value={recipientControllerType} onChange={(_event: any) => handleRecipientControllerTypeChanged(_event.target.value)}>
                        <option value='all'>Minden sziget</option>
                        <option value='county'>Megye szigetei</option>
                        <option value='settlement'>Város szigetei</option>
                        <option value='island'>Sziget</option>
                    </select>
                    {recipientControllerType != 'all' && <div>
                        {recipientControllerType == 'county' && <select defaultValue={COUNTIES[0]} onChange={(_event: any) => setRecipientToAdd('county:' + _event.target.value)}>
                            {COUNTIES.map((county: string) => (
                                <option key={county} value={county}>{county}</option>
                            ))}
                        </select>}
                        {recipientControllerType == 'settlement' && <input value={recipientToAdd ? recipientToAdd.split(':')[1] : ''} onFocus={() => setSettlementSelectOpened(true)} style={{ minWidth: 300 }} readOnly placeholder='Város választása' />}
                        {recipientControllerType == 'island' && <input value={recipientToAdd ? recipientToAdd.split(':')[2] : ''} onFocus={() => setIslandSelectOpened(true)} style={{ minWidth: 300 }} readOnly placeholder='Sziget választása' />}
                    </div>}
                    <button onClick={addRecipient}>Hozzáadás</button>
                </div>
                {recipients.size < 1 && <p className='send-message-no-data'>Még nem adtál hozzá egyetlen címzetett sem.</p>}
                {recipients.size > 1 && recipients.has('all') && <p className='send-message-data-warning'>Minden szigetet meg fogja kapni az üzenetet!<br></br>A többi feltétel így jelentéktelen!</p>}
                <div className='send-message-recipient-list'>
                    {Array.from(recipients).map((recipient: any) => (
                        <div className='send-message-recipient-item' key={recipient}>
                            {getRecipientDisplayName(recipient)}
                            <XMarkIcon onClick={() => removeRecipient(recipient)} className='send-message-remove-recipient-button remove-item-xmark' />
                        </div>
                    ))}
                </div>
            </div>

            <div id='send-message-bottom-action-buttons'>
                <button disabled={messageSending} onClick={sendMessage} className='primary-button send-message-button light-shadow'>Küldés</button>
                <button onClick={setUpAndShowMessagePreview}>Üzenet előnézete</button>
            </div>

        </div>}
    </div>
}

export default SendMessagePage;