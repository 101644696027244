/**
 * Lists the senders from the server.
 * @param payload the payload to set
 * @returns the response with the senders list
 */
async function listSenders(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/message/senders?page=${payload.page}&count=${payload.count}&activated=${payload.activated}&keyword=${payload.keyword}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                }
            });

            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const pageCount = response.headers.get('X-Page-Count');

            const data = await response.json();
            resolve({ headers: { pageCount }, data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Sends the message payload to the server.
 * @param payload the payload to send
 * @returns the response of the request
 */
async function create(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {

            const formData = new FormData();
            formData.append('type', payload.type);
            formData.append('sender', payload.sender);
            formData.append('title', payload.title);
            formData.append('body', payload.body);
            formData.append('recipients', JSON.stringify(payload.recipients));
            formData.append('tags', JSON.stringify(payload.tags));
            formData.append('priority', payload.priority);
            formData.append('videoURL', payload.videoURL);
            formData.append('poll', JSON.stringify(payload.poll));
            
            payload.images.forEach((image: any, index: number) => {
                formData.append(`images`, image);
            });

            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/message/create`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${sessionToken}`
                }
            });

            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Lists the user's messages from the server.
 * @param payload the payload to set
 * @returns the response with the messages for the current user
 */
async function list(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/message/list?page=${payload.page}&count=${payload.count}&tagsFilter=${payload.tagsFilter}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                }
            });

            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const pageCount = response.headers.get('X-Page-Count');

            const data = await response.json();
            resolve({ headers: { pageCount }, data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Fetches the message data and all content from the server.
 * @param messageId the id of the message to get
 * @returns The message data
 */
async function get(messageId: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/message/content?id=${messageId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                }
            });

            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Sends the seen request to the API backend.
 * @param messageId the id of the message
 * @returns OK if the action was successful
 */
async function seen(messageId: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/message/seen`, {
                method: 'POST',
                body: JSON.stringify({ messageId }),
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Sends the user's vote to the backend API server.
 * @param messageId the id of the message to vote
 * @param vote the selected vote value
 * @returns the response
 */
async function vote(messageId: string, vote: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/message/vote`, {
                method: 'POST',
                body: JSON.stringify({ messageId, vote }),
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Fetches the message tags from the server.
 * @param keyword the keyword to search
 * @returns the response.
 */
async function listTags(keyword: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/message/list/tags?keyword=${keyword}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                }
            });

            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

export default {
    listSenders,
    create,
    list,
    get,
    seen,
    vote,
    listTags
};