import { useRef, useState } from 'react';
import './crop-image.styles.css';

import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function CropImageDialog(props: any) {

    const [crop, setCrop] = useState<Crop>();
    const imgRef = useRef<HTMLImageElement>(null);

    const [imageReturning, setImageReturning] = useState<boolean>(false);

    /**
     * Send a close request without any
     * result to the parent component.
     */
    function closeDialog(): void {
        props.onClose(null);
    }

    /**
     * Called when the user finished the cropping.
     * Passes the image back to the parent component.
     */
    async function done(): Promise<void> {
        // Return on undefined or null
        if (!imgRef || !imgRef.current || !crop) {
            return;
        }

        setImageReturning(true);

        const image = imgRef.current;

        // This will size relative to the uploaded image
        // size. If you want to size according to what they
        // are looking at on screen, remove scaleX + scaleY
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        // Create a canvas element to draw the cropped image
        const canvas = document.createElement('canvas');
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;

        const ctx = canvas.getContext('2d');
        if (!ctx) {
            throw new Error('No 2d context');
        }

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height
        );

        // Convert the canvas to a Blob and pass it back to the parent component
        canvas.toBlob((blob) => {
            if (blob) {
                setImageReturning(false);
                props.onClose(blob);
            }
        }, 'image/jpeg', 0.9);
    }

    /**
     * Called when the image is loaded
     * and places the crop into the center of it
     * keeping the defined aspect ratio.
     * @param _event the image load event
     */
    function onImageLoad(_event: any) {
        const { width, height } = _event.currentTarget;
        const aspect = props.aspect || 1;

        // Choose the smaller dimension and apply the aspect ratio to make the crop square
        const cropSize = Math.min(width, height) * 0.9; // 90% of the smallest dimension

        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: 'px',
                    width: cropSize,
                    height: cropSize / aspect
                },
                aspect,
                width,
                height
            ),
            width,
            height
        );

        setCrop(crop);
    }

    return (
        <div id='crop-image-dialog' className='fade-in-up'>
            <div id='crop-image-dialog-content' className='light-shadow'>
                <ReactCrop
                    aspect={props.aspect}
                    crop={crop}
                    onChange={c => setCrop(c)}
                >
                    <img style={{ width: '100%' }} ref={imgRef} src={props.image} onLoad={onImageLoad} />
                </ReactCrop>
                <div id='crop-image-buttons'>
                    <button disabled={imageReturning} onClick={done} className='primary-button minimum-shadow'>Kész</button>
                    <button onClick={closeDialog}>Mégsem</button>
                </div>
            </div>
        </div >
    );
}

export default CropImageDialog;

function centerAspectCrop(width: any, height: any, aspect: any): import("react").SetStateAction<Crop | undefined> {
    throw new Error('Function not implemented.');
}
