import { useEffect, useState } from 'react';
import './select-tag.styles.css';

import MessageService from '../../services/message.service';


function SelectTagDialog(props: any) {

    const [tags, setTags] = useState<any>([]);
    const [searchKeyword, setSearchKeyword] = useState<string>('');

    useEffect(() => {
        fetchTags();
    }, [searchKeyword]);

    /**
     * Fetches the tags from the server.
     */
    function fetchTags(): void {
        MessageService.listTags(searchKeyword)
            .then((response: any) => {
                setTags(response.data);
            });
    }

    /**
     * Closes the dialog.
     * @param _event the click event
     */
    function closeDialog(_event: any): void {
        props.onClose(null);
    }

    /**
     * Returns the selected tag to the parent component.
     * @param tag the selected tag
     */
    function selectTag(tag: string): void {
        props.onClose(tag);
    }

    return (<div id='select-tag-dialog'>
        <div id='select-tag-dialog-content' className='fade-in-up light-shadow'>
            <h3 id='select-tag-title'>Címke kiválasztása</h3>

            <div id="select-tag-form">
                <div id='select-tag-controls'>
                    <input onChange={(_event: any) => setSearchKeyword(_event.target.value)} className='select-tag-form-input' placeholder='Keresés név alapján' />
                </div>

                <div id='select-tag-tags-list'>
                    {tags.map((tag: any) => (
                        <div onClick={() => selectTag(tag.name)} className='select-tag-tag-item minimum-shadow' key={tag.name}>
                            <div className='select-tag-tag-name'>{tag.name}</div>
                            <div className='select-tag-tag-entry-count'>{tag.count}</div>
                        </div>
                    ))}

                    {tags.length < 1 && <p id='select-tag-no-data'>Nincs megjeleníthető címke</p>}

                    <button onClick={closeDialog}>Bezárás</button>
                </div>
            </div>
        </div>
    </div>);
}

export default SelectTagDialog;