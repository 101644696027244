import { useEffect, useRef, useState } from 'react';
import './island-members.styles.css';
import { toast } from 'react-toastify';

import IslandService from '../../../../../../services/island.service';
import ArrowLeftIcon from '../../../../../../icons/arrow-left.icon';
import ArrowRightIcon from '../../../../../../icons/arrow-right.icon';
import Loader from '../../../../../../components/loader/loader.component';
import XMarkIcon from '../../../../../../icons/xmark.icon';
import EnvelopeIcon from '../../../../../../icons/envelope.icon';
import { authProvider } from '../../../../../../auth.provider';
import { useNavigate } from 'react-router-dom';

function IslandMembersComponent(props: any) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);

    const count = 50;
    const [accepted, setAccepted] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [searchEmailKeyword, setSearchEmailKeyword] = useState<string>('');

    const [members, setMembers] = useState<Array<any>>([]);

    const previousValues = useRef({ searchEmailKeyword, accepted });

    useEffect(() => {

        // If the keyword or the activated modified reset the page
        if (previousValues.current.searchEmailKeyword != searchEmailKeyword ||
            previousValues.current.accepted != accepted) {
            setPage(1);
        }

        getIslandMembers();

        // Update previous values after checks
        previousValues.current = { searchEmailKeyword, accepted };

    }, [accepted, page, searchEmailKeyword]);

    /**
     * Fetches the island members
     * paginated from the server.
     */
    function getIslandMembers(): void {
        setLoading(true);

        IslandService.members({ islandId: props.id, accepted, page, count, keyword: searchEmailKeyword })
            .then((response: any) => {
                setMembers(response.data);
                setPageCount(response.headers.pageCount);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    toast.error(err.status);
                    return;
                }
            });
    }

    /**
     * Modifies the accepted value to modify
     * the users view.
     * @param data the accepted view value
     */
    function modifyUsersView(data: any) {
        const accepted = data === 'true';
        setAccepted(accepted);
    }

    /**
     * Handles the pagination by adding the index to the page.
     * @param index the index to add
     */
    function paginateTable(index: number): void {
        if (page + index < 1 || page + index > pageCount) {
            return;
        }
        setPage(page + index);
    }

    /**
     * Removes the member from the island.
     * @param email the email of the member to remove
     */
    function removeMember(email: string): void {
        IslandService.removeMember({ islandId: props.id, email })
            .then(() => {
                getIslandMembers();
            })
            .catch((err) => {
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    toast.error(err.status);
                    return;
                }
            });
    }

    /**
     * Sends the invitation email again with a different
     * code to the member.
     * @param email the email of the user.
     */
    function resendMemberInvitationEmail(email: string): void {
        IslandService.refreshInvitation({ islandId: props.id, email })
            .then(() => {
                toast.info('Invitáció újra elküldve!');
            })
            .catch((err) => {
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    toast.error(err.status);
                    return;
                }
            });
    }

    return (
        <div className='fade-in-up'>

            <div id='island-members-controls'>
                <select className='island-members-controls-select' value={accepted.toString()} onChange={(_event: any) => modifyUsersView(_event.target.value)}>
                    <option value='true'>Tagok</option>
                    <option value='false'>Meghívottak</option>
                </select>
                <input onKeyUp={(_event: any) => setSearchEmailKeyword(_event.target.value)} id='island-members-search-input' placeholder='Keresés e-mail cím alapján' />
                <div id='island-members-pagination'>
                    <ArrowLeftIcon onClick={() => paginateTable(-1)} className='pagination-arrow-button' />
                    {page} / {pageCount}
                    <ArrowRightIcon onClick={() => paginateTable(1)} className='pagination-arrow-button' />
                </div>
            </div>

            {loading && <div className='subpage-loader'>
                <Loader />
            </div>}

            {!loading && members.length < 1 && <p className='island-members-no-data fade-in-up'>Nincs megjeleníthető eredmény!</p>}

            {!loading && members.length > 0 && <table className='light-shadow island-members-list-table fade-in-up'>
                <thead>
                    <tr>
                        <th>Név</th>
                        <th>E-mail</th>
                        <th>Telefonszám</th>
                        <th>Szerep</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {members.map((member: any) => (
                        <tr key={member.email}>
                            <td>{member.name}</td>
                            <td>{member.email}</td>
                            <td>{member.telephone}</td>
                            <td>{member.role}</td>
                            <td className='island-members-action-buttons'>
                                {accepted == false && <div onClick={() => resendMemberInvitationEmail(member.email)} className='island-registration-action-button'>Invitáció</div>}
                                <div onClick={() => removeMember(member.email)} className='island-registration-action-button island-registration-decline-button'>Eltávolít</div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
        </div>
    );

}

export default IslandMembersComponent;