import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MessageService from '../../../../services/message.service';
import { toast } from 'react-toastify';
import { authProvider } from '../../../../auth.provider';

import './message.styles.css';
import Loader from '../../../../components/loader/loader.component';

import { Fade } from 'react-slideshow-image';
import DefaultUserIcon from '../../../../assets/default-user-icon.jpg';

import { BarChart } from '@mui/x-charts';

function MessagePage() {

    const { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [message, setMessage] = useState<any>(null);

    // Send the seen request to the message
    if (id) {
        MessageService.seen(id);
    }

    useEffect(() => {
        getMessage();
    }, []);

    /**
     * Fetches and sets the id given message.
     */
    function getMessage(needLoading: boolean = true): void {
        if (needLoading) {
            setLoading(true);
        }
        if (id) {
            MessageService.get(id)
                .then(async response => {
                    setLoading(false);

                    // Download the images from the server with authentication headers.
                    const downloadedImages = await Promise.all(
                        response.data.images = response.data.images.map(async (image: any) => await fetchImage(image))
                    );

                    response.data.images = downloadedImages;

                    if (response.data.type == 'poll') {
                        const labels: Array<string> = new Array<string>();
                        const datas: Array<number> = new Array<number>();

                        // Process the data for the arrays
                        response.data.poll.answers.forEach((answer: string) => {
                            labels.push(answer);
                            datas.push(response.data.poll.votesData.find((a: any) => a.answer == answer).count);
                        });

                        response.data.poll.votesData = {
                            labels: labels,
                            datas: datas
                        };
                    }

                    setMessage(response.data);
                })
                .catch((err) => {
                    if (err == 'A munkamenet lejárt!') {
                        toast.error(err);
                        setTimeout(() => {
                            authProvider.signout();
                            navigate('/login');
                        }, 2000);
                        return;
                    }

                    if (err.error) {
                        toast.error(err.error);
                        return;
                    }

                    if (err.status) {
                        toast.error(err.status);
                        return;
                    }
                });
        }
    }

    /**
     * Fetches the restricted image from the server.
     * @param imageURL the url of the image
     * @returns 
     */
    async function fetchImage(imageURL: string) {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(imageURL, {
                headers: {
                    Authorization: `Bearer ${sessionToken}`,
                }
            });
            if (!response.ok) throw new Error('Failed to fetch image');

            const blob = await response.blob();
            return (URL.createObjectURL(blob));
        } catch (error) {
            toast.error('Nem sikerült letölteni a képet!');
        }
    }

    /**
     * Sends the vote to the backend API server.
     * @param vote the vote to send
     */
    function sendPollVote(vote: string): void {
        if (id) {
            MessageService.vote(id, vote)
                .then(() => getMessage(false))
                .catch((err) => {
                    if (err == 'A munkamenet lejárt!') {
                        toast.error(err);
                        setTimeout(() => {
                            authProvider.signout();
                            navigate('/login');
                        }, 2000);
                        return;
                    }

                    if (err.error) {
                        toast.error(err.error);
                        return;
                    }

                    if (err.status) {
                        toast.error(err.status);
                        return;
                    }
                });
        }
    }

    return (
        <div className='subpage-window'>

            {loading && <div className='subpage-loader'>
                <Loader />
            </div>}

            {!loading && message && <div id='message-page-content' className='fade-in-up'>
                {message.videoURL != '' && <iframe className='message-page-video light-shadow' width="100%" height="500" src={message.videoURL} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>}
                {message.images.length > 1 && <div className="slide-container">
                    <Fade cssClass='message-page-slideshow light-shadow' transitionDuration={300} autoplay={false}>
                        {message.images.map((slideImage: any, index: number) => (
                            <div key={index}>
                                <img className='message-page-image-item' src={slideImage} />
                            </div>
                        ))}
                    </Fade>
                </div>}

                {message.images.length == 1 && <div className="slide-container">
                    <div className='message-page-standalone-image-slideshow light-shadow'>
                        <img className='message-page-image-item' src={message.images[0]} />
                    </div>
                </div>}


                <div id='message-page-sender-container'>
                    {message.sender.avatar == 'default' && <img src={DefaultUserIcon} id='message-page-sender-image' className='minimum-shadow' />}
                    {message.sender.avatar != 'default' && <img src={message.sender.avatar} id='message-page-sender-image'  />}
                    <div>
                        <h4 id='message-page-sender-name'>{message.sender.name}</h4>
                        <p id='message-page-created'>{new Date(message.created).toLocaleDateString()}</p>
                    </div>
                </div>
                <h1 id='message-page-title'>{message.title}</h1>

                <div id='message-page-tag-list'>
                    {message.tags.map((tag: string) => (
                        <div key={tag} className='message-page-tag-item'>{tag}</div>
                    ))}
                </div>

                <p id='message-page-body'>{message.body}</p>

                {message.type == 'poll' && <div id='message-page-poll-container' className='minimum-shadow'>
                    <h4 className='message-page-section-title'>Szavazás</h4>
                    <h5 id='message-page-poll-question'>{message.poll.question}</h5>
                    <p id='message-page-poll-description'>Választásod automatikusan elküldésre kerül,<br></br>de később módosíthatsz még rajta.</p>
                    <div id='message-page-chart'>
                        <div>
                            <div className='radio-group-vertical message-page-poll-radio-group minimum-shadow'>
                                {message.poll.answers.map((answer: string) => (
                                    <div key={answer} className='radio-group-item'>
                                        <label className='radio-group-item-label'>
                                            <input checked={message.poll.userPreviousVote == answer} onChange={(_event: any) => sendPollVote(_event.target.value)} value={answer} className='radio-group-item-button' type='radio' name='vote'></input>
                                            {answer}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <BarChart
                            xAxis={[
                                {
                                    id: 'barCategories',
                                    data: message.poll.votesData.labels,
                                    scaleType: 'band',
                                    colorMap: {
                                        type: 'ordinal',
                                        colors: ['#081a38', '#fa3e4b', '#00ac6d', '#26a4ed', '#faf614', '#fa145d'],
                                    }
                                },
                            ]}
                            series={[
                                { data: message.poll.votesData.datas }
                            ]}
                            width={1000}
                            height={400}
                        />
                    </div>
                </div>}
            </div>}
        </div>
    );
}

export default MessagePage;