
/**
 * Sends an OTP login code to the user's email address.
 * @param payload the payload to send
 * @returns the respone status OK if the code sent
 */
async function otp(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch('/api/v1/auth/otp', {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-type': 'application/json'
                }
            });
            if (!response.ok) {
                const errorResponse = await response.json();
                return reject(errorResponse);
            }
            const data = await response.json();
            resolve(data);
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Authenticates the user into the server API.
 * @param payload the payload to send
 * @returns returns the user's data and the session token
 */
async function login(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch('/api/v1/auth/login', {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-type': 'application/json'
                }
            });
            if (!response.ok) {
                const errorResponse = await response.json();
                return reject(errorResponse);
            }
            const data = await response.json();

            const token = response.headers.get('X-Session-Token');
            if (!token) {
                reject('Hiba a session létrehozása közben!');
            } else {
                localStorage.setItem('SESSION_KEY', token);
            }

            resolve(data);
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Sends the user email address for validation.
 * @param payload the payload to send
 * @returns the a status OK if the email sent
 */
async function registrationOtp(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch('/api/v1/auth/registration-otp', {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-type': 'application/json'
                }
            });
            if (!response.ok) {
                const errorResponse = await response.json();
                return reject(errorResponse);
            }
            const data = await response.json();
            resolve(data);
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Sends the user registration form payload to the API server
 * which creates the user in the database.
 * @param payload the payload to send
 * @returns returns a status OK if the user created in the database
 */
async function registration(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch('/api/v1/auth/registration', {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-type': 'application/json'
                }
            });
            if (!response.ok) {
                const errorResponse = await response.json();
                return reject(errorResponse);
            }
            const data = await response.json();
            resolve(data);
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Refreshes the session token.
 */
async function refresh(): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch('/api/v1/auth/refresh', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${sessionToken}`
                }
            });
            
            const token = response.headers.get('X-Session-Token')

            if (token) {
                localStorage.setItem('SESSION_KEY', token);
            }

            resolve(true);
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

export default {
    otp,
    login,
    registrationOtp,
    registration,
    refresh
};