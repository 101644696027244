import { Outlet, useNavigate } from 'react-router-dom';
import './home.styles.css';
import UserIcon from '../../icons/user.icon';
import DoorOpenIcon from '../../icons/door-open.icon';
import UserGroupIcon from '../../icons/user-group.icon';
import { authProvider } from '../../auth.provider';
import EnvelopeIcon from '../../icons/envelope.icon';
import IslandTropicalIcon from '../../icons/island.icon';
import TiszaLogo from '../../assets/tiszalogo.png';
import { useEffect, useRef, useState } from 'react';
import AuthenticationService from '../../services/authentication.service';
import ServerIcon from '../../icons/server.icon';

function HomePage() {

    const navigate = useNavigate();

    const sidebarRef = useRef<any>(null);
    const dynamicPaddingRef = useRef<any>(null);
    const [padding, setPadding] = useState<any>('0px');

    useEffect(() => {
        const updatePadding = () => {
            if (sidebarRef.current) {
                const referenceWidth = sidebarRef.current.offsetWidth;
                setPadding(`${referenceWidth}px`);
            }
        };

        // Initial update
        updatePadding();

        // Set up a timer to update padding at intervals
        const intervalId = setInterval(async () => {
            await updateSessionToken();
        }, 1 * (60 * 1000));

        // Update padding on window resize
        window.addEventListener('resize', updatePadding);

        // Clean up event listener on unmount
        return () => {
            clearInterval(intervalId);
            window.removeEventListener('resize', updatePadding);
        };
    }, []);

    let user: any = localStorage.getItem('USER');
    if (!user) {
        console.error('User data not found');
        localStorage.removeItem('SESSION_KEY');
        navigate('/login');
    } else {
        user = JSON.parse(user);
    }

    /**
     * Updates the session token for the user.
     */
    async function updateSessionToken(): Promise<void> {
        const sessionToken = localStorage.getItem('SESSION_KEY');
        if (sessionToken) {
            const tokenData = JSON.parse(atob(sessionToken.split('.')[1]));
            const currentTime = Math.floor(Date.now() / 1000);

            // If the token exipre less then 5 minutes
            if (tokenData.exp - currentTime <= 60 * 5) {
                console.info('Refresh the session token!');
                await AuthenticationService.refresh();
            }
        }
    }

    /**
     * Logs the user out from the current session.
     */
    function logout() {
        authProvider.signout();
        navigate('/login');
    };

    return <>
        {user != null && <div id='home-window'>
            <div ref={sidebarRef} id='home-menu-sidebar' className='minimum-shadow'>
                <div id='signed-in-user-panel'>
                    <img id="signed-in-user-icon" src={TiszaLogo} />
                    <div>
                        <h4 id='signed-in-user-name'>{user.name}</h4>
                        <p id='signed-in-user-type'>{user.email}</p>
                    </div>
                </div>

                <div id='home-menu-buttons'>
                    <div onClick={() => navigate('/home/messages')} className='home-menu-button'>
                        <EnvelopeIcon className='home-menu-button-icon' />
                        Üzenetek
                    </div>
                    <div onClick={() => navigate('/home/myislands')} className='home-menu-button'>
                        <IslandTropicalIcon className='home-menu-button-icon' />
                        Szigeteim
                    </div>
                    <div onClick={() => navigate('/home/profile')} className='home-menu-button'>
                        <UserIcon className='home-menu-button-icon home-menu-button-user-icon' />
                        Profil
                    </div>
                    {user.roles.includes('ADMIN') && <div className='home-menu-admin-submenu'>
                        <div className='home-menu-admin-submenu-title'>Admin funkciók</div>
                        <div onClick={() => navigate('/home/users')} className='home-menu-button'>
                            <UserGroupIcon className='home-menu-button-icon' />
                            Felhasználók
                        </div>
                        <div onClick={() => navigate('/home/all-islands')} className='home-menu-button'>
                            <IslandTropicalIcon className='home-menu-button-icon' />
                            Szigetek listája
                        </div>
                        <div onClick={() => navigate('/home/send-message')} className='home-menu-button'>
                            <EnvelopeIcon className='home-menu-button-icon' />
                            Üzenet küldése
                        </div>
                    </div>}

                    {user.roles.includes('SUPERADMIN') && <div className='home-menu-admin-submenu' style={{borderTop: 0}}>
                        <div className='home-menu-admin-submenu-title'>SuperAdmin funkciók</div>
                        <div onClick={() => navigate('/home/server')} className='home-menu-button'>
                            <ServerIcon className='home-menu-button-icon' />
                            Szerver
                        </div>
                    </div>}

                    <div onClick={logout} className='home-menu-button'>
                        <DoorOpenIcon className='home-menu-button-icon' />
                        Kijelentkezés
                    </div>
                </div>

            </div>
            <div style={{ paddingLeft: padding }} ref={dynamicPaddingRef} id='home-subpages-window'>
                <Outlet />
            </div>
        </div>}
    </>
}

export default HomePage;