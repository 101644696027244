const COUNTIES = [
    'Bács-Kiskun vármegye',
    'Baranya vármegye',
    'Békés vármegye',
    'Borsod-Abaúj-Zemplén vármegye',
    'Csongrád-Csanád vármegye',
    'Fejér vármegye',
    'Győr-Moson-Sopron vármegye',
    'Hajdú-Bihar vármegye',
    'Heves vármegye',
    'Jász-Nagykun-Szolnok vármegye',
    'Komárom-Esztergom vármegye',
    'Nógrád vármegye',
    'Pest vármegye',
    'Somogy vármegye',
    'Szabolcs-Szatmár-Bereg vármegye',
    'Tolna vármegye',
    'Vas vármegye',
    'Veszprém vármegye',
    'Zala vármegye'
];

export default COUNTIES;