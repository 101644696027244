import { useEffect, useRef, useState } from 'react';
import ArrowLeftIcon from '../../../../icons/arrow-left.icon';
import ArrowRightIcon from '../../../../icons/arrow-right.icon';
import './all-islands.styles.css';
import Loader from '../../../../components/loader/loader.component';
import { toast } from 'react-toastify';

import IslandService from '../../../../services/island.service';
import { authProvider } from '../../../../auth.provider';
import { useNavigate } from 'react-router-dom';

function AllIslandsPage() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [status, setStatus] = useState<string>('completed');

    const count = 50;
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [searchNameKeyword, setNameEmailKeyword] = useState<string>('');

    const [islands, setIslands] = useState<Array<any>>([]);

    const previousValues = useRef({ searchNameKeyword, status });

    useEffect(() => {

        // If the keyword or the activated modified reset the page
        if (previousValues.current.searchNameKeyword != searchNameKeyword ||
            previousValues.current.status != status) {
            setPage(1);
        }

        listAllIslands();

        // Update previous values after checks
        previousValues.current = { searchNameKeyword, status };

    }, [status, page, searchNameKeyword]);

    /**
     * Lists all the islands with pagination
     * and filters.
     */
    function listAllIslands(): void {
        setLoading(true);
        IslandService.listAll({ page, count, keyword: searchNameKeyword, status })
            .then((response: any) => {
                setIslands(response.data);
                setPageCount(response.headers.pageCount);
                setLoading(false);
            })
            .catch((err) => {
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    toast.error(err.status);
                    return;
                }
            });
    }

    /**
     * Modifies the islands status view.
     * @param status the new status to set
     */
    function modifyStatus(status: string) {
        setStatus(status);
    }

    /**
     * Updates the island status.
     * @param id the id of the island
     * @param status the new status to set
     */
    function updateStatus(id: string, status: string): void {
        IslandService.updateStatus({ islandId: id, status })
            .then(() => {
                listAllIslands();
            })
            .catch((err) => {
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    toast.error(err.status);
                    return;
                }
            });
    }

    /**
     * Handles the pagination by adding the index to the page.
     * @param index the index to add
     */
    function paginateTable(index: number): void {
        if (page + index < 1 || page + index > pageCount) {
            return;
        }
        setPage(page + index);
    }

    return (
        <div className='subpage-window'>

            <h2 className="subpage-title">Szigetek listája</h2>

            <div id='users-controls'>
                <select className='users-controls-select' onChange={(_event: any) => modifyStatus(_event.target.value)} value={status}>
                    <option value='completed'>Bejegyzett</option>
                    <option value='pending'>Bejegyzés alatt</option>
                    <option value='waiting-for-approve'>Jóváhagyásra vár</option>
                </select>
                <input id='users-search-input' placeholder='Keresés név cím alapján' />
                <div id='users-pagination'>
                    <ArrowLeftIcon onClick={() => paginateTable(-1)} className='pagination-arrow-button' />
                    {page} / {pageCount}
                    <ArrowRightIcon onClick={() => paginateTable(1)} className='pagination-arrow-button' />
                </div>
            </div>

            {loading && <div className='subpage-loader'>
                <Loader />
            </div>}

            {!loading && islands.length < 1 && <p className='all-islands-no-data fade-in-up'>Nincs megjeleníthető eredmény!</p>}

            {!loading && islands.length > 0 && <div className='fade-in-up'>
                <table className='light-shadow user-list-table'>
                    <thead>
                        <tr>
                            <th>Név</th>
                            <th>Kapcsolattartó neve</th>
                            <th>Kapcsolattartó e-mail címe</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {islands.map((island: any) => (
                            <tr key={island._id}>
                                <td>{island.name}</td>
                                <td>{island.owner.name}</td>
                                <td>{island.owner.email}</td>
                                <td>
                                    <div className='island-approve-action-panel'>
                                        <div className='island-registration-action-button'>Részletek</div>
                                        {status == 'waiting-for-approve' && <>
                                            <div onClick={() => updateStatus(island._id, 'pending')} className='island-registration-action-button'>Elfogad</div>
                                            <div onClick={() => updateStatus(island._id, 'declined')} className='island-registration-action-button island-registration-decline-button'>Elutasít</div>
                                        </>}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
        </div>
    )
}

export default AllIslandsPage;