import './island-create.styles.css';

import { useRef, useState } from "react";
import { toast } from 'react-toastify';

import COUNTIES from '../../../../constants/counties';
import AddIslandMemberDialog from "../../../../components/add-island-member/add-island-member.dialog";

import IslandService from "../../../../services/island.service";
import { authProvider } from "../../../../auth.provider";
import { useNavigate } from "react-router-dom";
import XMarkIcon from "../../../../icons/xmark.icon";
import SelectSettlementDialog from '../../../../components/select-settlement/select-settlement.dialog';
import CropImageDialog from '../../../../components/crop-image/crop-image.dialog';

function IslandCreatePage() {

    const navigate = useNavigate();

    const [memberAddPanel, setMemberAddPanel] = useState<boolean>(false);
    const [selectSettlementPanel, setSelectSettlementPanel] = useState<boolean>(false);
    const [showImageCrop, setShowImageCrop] = useState<boolean>(false);
    const [imageToCrop, setImageToCrop] = useState<any>(null);

    const logoInputRef = useRef<any>(null);

    const [islandName, setIslandName] = useState<string>('');
    const [county, setCounty] = useState<string>('Bács-Kiskun vármegye');
    const [city, setCity] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [introduction, setIntroduction] = useState<string>('');
    const [logoFile, setLogoFile] = useState<any>(null);
    const [members, setMembers] = useState<any[]>([]);

    const [logoPreview, setLogoPreview] = useState<any>(null);

    const [islandCreating, setIslandCreating] = useState<boolean>(false);

    /**
     * Opens the member add dialog.
     */
    function openAddMemberDialog(): void {
        setMemberAddPanel(true);
    }

    /**
     * Called when the add member dialog closed.
     * @param member the payload of the addded member
     */
    function onAddMemberClosed(member: any): void {

        if (!member) {
            setMemberAddPanel(false);
            return;
        }

        let user: any = localStorage.getItem('USER');
        if (!user) {
            toast.error('Hiba történt a session lekérdezése közben!');
            return;
        }

        user = JSON.parse(user);

        // Prevent to add yourself
        if (member.email.toLowerCase() == user.email.toLowerCase()) {
            toast.error('Saját magad nem hívhatod meg!');
            return;
        }

        // Checking that the member email already added
        const memberRef = members.find((m: any) => m.email.toLowerCase() == member.email.toLowerCase());
        if (memberRef) {
            toast.error('Egy tag már fel lett véve ezzel az email címmel!');
            return;
        }

        setMemberAddPanel(false);
        if (member) {
            members.push(member);
            setMembers(members);
        }
    }

    /**
     * Called when the select settlement dialog closed.
     * @param settlement the result of the settlement dialog.
     */
    function onSelectSettlementClosed(settlement: any): void {
        setSelectSettlementPanel(false);
        if (settlement) {
            setCity(settlement);
        }
    }

    /**
     * Opens the file browser to select a new logo.
     */
    function addLogo(): void {
        if (logoInputRef.current) {
            logoInputRef.current.click();
        }
    }

    /**
     * Handles the image load into the island logo.
     * @param _event the file browser close event
     */
    function handleLogoChange(_event: any): void {
        const logoFile = _event.target.files[0];
        logoInputRef.current.value = '';

        if (logoFile) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setImageToCrop(reader.result);
                setShowImageCrop(true);
            };

            reader.readAsDataURL(logoFile);
        }
    }

    /**
     * Called when the image crop dialog closed.
     * @param image the cropped iamge
     */
    function onLogoCropClosed(image: any): void {
        setImageToCrop(null);
        setShowImageCrop(false);

        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoFile(image);
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(image);
        }
    }

    /**
     * Create a payload from the form data
     * and sends it to the API.
     */
    function create(): void {
        setIslandCreating(true);
        const payload = {
            name: islandName,
            county: county,
            city: city,
            address: address,
            introduction: introduction,
            logo: logoFile,
            members: members
        };
        IslandService.create(payload)
            .then((_) => {
                setIslandCreating(false);
                navigate('/home/myislands');
            })
            .catch((err) => {
                setIslandCreating(false);
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    toast.error(err.status);
                    return;
                }
            });
    }

    /**
     * Remove the member by the given email.
     * @param email the email of the member to remove
     */
    function removeMember(email: string): void {
        const filteredMembers = members.filter((member: any) => member.email != email);
        setMembers(filteredMembers);
    }

    return (
        <div className='subpage-window fade-in-up'>

            {memberAddPanel && <AddIslandMemberDialog onClose={onAddMemberClosed} />}
            {selectSettlementPanel && <SelectSettlementDialog onClose={onSelectSettlementClosed} />}
            {showImageCrop && <CropImageDialog aspect={1} image={imageToCrop} onClose={onLogoCropClosed} />}


            <h2 className="subpage-title">Sziget alapítása</h2>
            <p className='island-description'>Az alábbi űrlap kitöltésével létrehozhatod saját szigeted!</p>
            <div id='island-registration-form'>
                <div>
                    <label className='form-item-label'>Sziget neve</label>
                    <input onKeyUp={(_event: any) => setIslandName(_event.target.value)} className='island-registration-input' placeholder='Sziget neve' />
                </div>

                <div>
                    <label className='form-item-label'>Székhely</label>
                    <div className='island-registration-inner-form'>
                        <div>
                            <select id="island-registration-county-select" onChange={(_event: any) => setCounty(_event.target.value)}>
                                {COUNTIES.map((county: string) => (
                                    <option key={county} value={county}>{county}</option>
                                ))}
                            </select>
                        </div>
                        <input readOnly onFocus={() => setSelectSettlementPanel(true)} value={city} className='island-registration-input' placeholder='Város' />
                        <input onKeyUp={(_event: any) => setAddress(_event.target.value)} className='island-registration-input' placeholder='Cím' />
                    </div>
                </div>

                <div>
                    <label className='form-item-label'>Bemutatkozás</label>
                    <textarea onKeyUp={(_event: any) => setIntroduction(_event.target.value)} className='island-registration-input' placeholder=''></textarea>
                </div>

                <div>
                    <label className='form-item-label'>Logo</label>
                    {logoPreview && <img id="island-registration-logo-preview" className='minimum-shadow' src={logoPreview} />}
                    <input onChange={(_event: any) => handleLogoChange(_event)} type="file" ref={logoInputRef} id="island-upload-logo" hidden />
                    <button onClick={addLogo}>Logo csatolása</button>
                </div>

                <div>
                    <label className='form-item-label'>Tagok (min. 5)</label>
                    <button onClick={openAddMemberDialog}>Tag hozzáadása</button>
                    {members.length < 1 && <p className='island-description' style={{ marginTop: 12 }}>Még nem adtál hozzá egyetlen tagot sem!</p>}
                    {members.length > 0 && <div className='island-registration-members'>
                        {members.map((member) => (
                            <div key={member.email} className='added-member-card light-shadow '>
                                <XMarkIcon onClick={() => removeMember(member.email)} className='added-member-card-remove-icon' />
                                <h4 className='added-member-card-name'>{member.name}</h4>
                                <div className='added-member-card-info'>{member.role}</div>
                                <div className='added-member-card-info'>{member.email}</div>
                                <div className='added-member-card-info'>{member.telephone}</div>
                            </div>
                        ))}
                    </div>}
                </div>

                <div>
                    <button disabled={islandCreating} onClick={create} className='primary-button'>Sziget létrehozása</button>
                </div>

            </div>
        </div>
    );
}

export default IslandCreatePage;