import { useEffect, useRef, useState } from 'react';
import './island-details.styles.css';
import Loader from '../../../../../../components/loader/loader.component';
import { toast } from 'react-toastify';

import IslandService from '../../../../../../services/island.service';
import { authProvider } from '../../../../../../auth.provider';
import { useNavigate } from 'react-router-dom';
import COUNTIES from '../../../../../../constants/counties';

function IslandDetailsComponent(props: any) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [island, setIsland] = useState<any>(null);

    const [logo, setLogo] = useState<any>(null);
    const [logoPreview, setLogoPreview] = useState<any>(null);
    const logoInputRef = useRef<any>(null);

    const [dataSaving, setDataSaving] = useState<boolean>(false);

    useEffect(() => {
        getIslandData();
    }, []);

    /**
     * Fetches the island data from the API server.
     */
    function getIslandData(): void {
        setLoading(true);
        IslandService.get(props.id)
            .then((response: any) => {
                setIsland(response.data);
                setLoading(false);
            })
            .catch((err) => {
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    toast.error(err.status);
                    return;
                }
            });
    }

    /**
     * Handles the island data property modifications.
     * @param property the property to modify
     * @param value the new value to set
     */
    function modifyIslandData(property: string, value: any): void {
        island[property] = value;
        setIsland(island);
    }

    /**
     * Opens the file browser to select a new logo.
     */
    function addLogo(): void {
        if (logoInputRef.current) {
            logoInputRef.current.click();
        }
    }

    /**
     * Handles the image load into the island logo.
     * @param _event the file browser close event
     */
    function handleLogoChange(_event: any): void {
        setLogo(_event.target.files[0]);

        if (_event.target.files[0]) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setLogoPreview(reader.result);
            };

            reader.readAsDataURL(_event.target.files[0]);
        }
    }

    /**
     * Sends the current island variable to the
     * API server to validate and save into the database.
     */
    function save(): void {
        setDataSaving(true);
        IslandService.update(island, logo)
            .then(() => {
                setDataSaving(false);
                props.onDataChanged();
            })
            .catch((err) => {
                setDataSaving(false);
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    toast.error(err.status);
                    return;
                }
            });
    }

    return (
        <div className='fade-in-up'>

            {loading && <div className='subpage-loader'>
                <Loader />
            </div>}

            {!loading && island && <div id='island-details-modification-form'>

                <div>
                    <label className='form-item-label'>Sziget neve</label>
                    <input onChange={(_event: any) => modifyIslandData('name', _event.target.value)} defaultValue={island.name} className='island-registration-input' placeholder='Sziget neve' />
                </div>

                <div>
                    <label className='form-item-label'>Székhely</label>
                    <div className='island-registration-inner-form'>
                        <div>
                            <select onChange={(_event: any) => modifyIslandData('county', _event.target.value)} defaultValue={island.county} id="island-registration-county-select">
                                {COUNTIES.map((county: string) => (
                                    <option key={county} value={county}>{county}</option>
                                ))}
                            </select>
                        </div>
                        <input onChange={(_event: any) => modifyIslandData('city', _event.target.value)} defaultValue={island.city} className='island-registration-input' placeholder='Város' />
                        <input onChange={(_event: any) => modifyIslandData('address', _event.target.value)} defaultValue={island.address} className='island-registration-input' placeholder='Cím' />
                    </div>
                </div>

                <div>
                    <label className='form-item-label'>Bemutatkozás</label>
                    <textarea onChange={(_event: any) => modifyIslandData('introduction', _event.target.value)} defaultValue={island.introduction} className='island-registration-input' placeholder=''></textarea>
                </div>

                <div>
                    <label className='form-item-label'>Logo</label>
                    {logo == null && <img id="island-registration-logo-preview" className='minimum-shadow' src={island.logo} />}
                    {logo != null && <img id="island-registration-logo-preview" className='minimum-shadow' src={logoPreview} />}
                    <input onChange={(_event: any) => handleLogoChange(_event)} type="file" ref={logoInputRef} id="island-upload-logo" hidden />
                    <button onClick={addLogo}>Logo módisítása</button>
                    {logo != null && <button onClick={() => { setLogo(null); setLogoPreview(null); }} style={{ marginLeft: 12 }}>Logo visszaállítása</button>}
                </div>

                <button disabled={dataSaving} onClick={save} className='primary-button'>Módosítások mentése</button>

            </div>}

        </div>
    );
}

export default IslandDetailsComponent;