import { toast } from 'react-toastify';
import './profile.styles.css';
import { useEffect, useRef, useState } from 'react';
import Loader from '../../../../components/loader/loader.component';
import UsersService from '../../../../services/users.service';
import { authProvider } from '../../../../auth.provider';
import { useNavigate } from 'react-router-dom';
import EnvelopeIcon from '../../../../icons/envelope.icon';
import PhoneIcon from '../../../../icons/phone.icon';
import BirthIcon from '../../../../icons/birth.icon';

import DefaultUserIcon from '../../../../assets/default-user-icon.jpg';

import moment from 'moment';
import CropImageDialog from '../../../../components/crop-image/crop-image.dialog';

function ProfilePage() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [profile, setProfile] = useState<any>(null);

    const avatarInputRef = useRef<any>(null);

    const [showCropImageDialog, setShowCropImageDialog] = useState<boolean>(false);
    const [imageToCrop, setImageToCrop] = useState<any>(null);

    useEffect(() => {
        fetchUserProfile();
    }, []);

    /**
     * Fetches the current user's profile data.
     */
    function fetchUserProfile(): void {
        UsersService.profile()
            .then((response: any) => {
                setLoading(false);
                setProfile(response.data);
            })
            .catch((err) => {
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    console.log('Fire');
                    toast.error(err.status);
                    return;
                }
            });
    }

    /**
     * Picks an image from the browser's default
     * file browser and sends it to the API server
     * to set up it to the user's avatar.
     */
    async function modifyAvatar(_event: any): Promise<void> {
        const avatarFile = _event.target.files[0];
        avatarInputRef.current.value = '';

        if (!avatarFile) {
            return;
        }

        // Load the image from the file source
        const loadedImage = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result));
            reader.readAsDataURL(avatarFile);
        });

        setImageToCrop(loadedImage);
        setShowCropImageDialog(true);
    }

    /**
     * Opens the default browser file picker.
     */
    function openAvatarPicker(): void {
        if (avatarInputRef.current) {
            avatarInputRef.current.click();
        }
    }

    /**
     * Called when the image cropped.
     * @param image the image returned by the cropper dialog.
     */
    function onImageCropped(image: any): void {
        setImageToCrop(null);
        setShowCropImageDialog(false);
        if (image) {
            // Send the file to the API server
            UsersService.updateAvatar(image)
                .then(_ => fetchUserProfile())
                .catch((err) => {
                    if (err == 'A munkamenet lejárt!') {
                        toast.error(err);
                        setTimeout(() => {
                            authProvider.signout();
                            navigate('/login');
                        }, 2000);
                        return;
                    }

                    if (err.error) {
                        toast.error(err.error);
                        return;
                    }

                    if (err.status) {
                        console.log('Fire');
                        toast.error(err.status);
                        return;
                    }
                });
        }
    }

    return (
        <div className='subpage-window fade-in-up'>

            {showCropImageDialog && <CropImageDialog aspect={1} image={imageToCrop} onClose={onImageCropped} />}

            {loading && <div className='subpage-loader'>
                <Loader />
            </div>}

            {!loading && profile && <div id='profile-page-content' className='fade-in-up'>
                <div id='profile-page-header'>
                    {profile.avatar == 'default' && <img src={DefaultUserIcon} className='profile-page-user-avatar minimum-shadow' />}
                    {profile.avatar != 'default' && <img src={profile.avatar} className='profile-page-user-avatar minimum-shadow' />}
                    <h1 className="subpage-title">{profile.name}</h1>
                </div>

                <div id='profile-page-informations'>
                    <div className='profile-page-information-item'>
                        <EnvelopeIcon className='profile-page-information-item-icon' />
                        <p className='profile-page-information-item-text'>{profile.email}</p>
                    </div>

                    <div className='profile-page-information-item'>
                        <PhoneIcon className='profile-page-information-item-icon' />
                        <p className='profile-page-information-item-text'>{profile.telephone}</p>
                    </div>

                    <div className='profile-page-information-item'>
                        <BirthIcon className='profile-page-information-item-icon' />
                        <p className='profile-page-information-item-text'>{moment(new Date(profile.birth)).format('LL')}</p>
                    </div>
                </div>

                <input onChange={(_event: any) => modifyAvatar(_event)} type='file' ref={avatarInputRef} hidden />
                <button onClick={openAvatarPicker} className='primary-button profile-page-modify-avatar-button minimum-shadow'>Profilkép módosítása</button>
            </div>}
        </div>
    );
}

export default ProfilePage;