import { useEffect, useState } from 'react';
import './myislands.styles.css';
import { toast } from 'react-toastify';

import IslandService from '../../../../services/island.service';
import { authProvider } from '../../../../auth.provider';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../components/loader/loader.component';

function MyIslandsPage() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [myIslands, setMyIslands] = useState<Array<any>>([]);

    useEffect(() => {
        listMyIslands();
    }, []);

    function listMyIslands(): void {
        setLoading(true);
        IslandService.list()
            .then((response: any) => {
                setMyIslands(response.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    toast.error(err.status);
                    return;
                }
            });
    }

    return (
        <div className='subpage-window'>
            <h2 className="subpage-title">Szigeteim</h2>

            <div id='my-islands-controls'>
                <input id='my-islands-search-bar' type='text' placeholder='Keresés név alapján' />
                <button onClick={() => navigate('/home/island-create')} style={{ minWidth: 150, height: 36 }} className='primary-button'>Sziget alapítása</button>
            </div>

            {loading && <div className='subpage-loader'>
                <Loader />
            </div>}

            {!loading && myIslands.length < 1 && <p className='my-island-no-islands fade-in-up'>Nem alapítottál még egy szigetet sem!<br></br>Sziget alapításához kattints a "Sziget alapítása" gombra!</p>}

            {!loading && myIslands.length > 0 && <div className='my-island-list fade-in-up'>
                {myIslands.map((island: any) => (
                    <div onClick={() => navigate(`/home/island/${island._id}`)} key={island._id} className='my-island-container minimum-shadow'>
                        <img className='my-island-logo' src={island.logo} />
                        <div>
                            <h4 className='my-island-title'>{island.name}</h4>
                            {island.status === 'waiting-for-approve' && <div className='island-overview-status-panel'>
                                <div className='island-overview-status-circle'></div>
                                <div>Jóváhagyásra vár</div>
                            </div>}

                            {island.status === 'pending' && <div className='island-overview-status-panel'>
                                <div className='island-overview-status-circle'></div>
                                <div>Bejegyzés alatt</div>
                            </div>}

                            {island.status === 'completed' && <div className='island-overview-status-panel'>
                                <div className='island-overview-status-circle island-overview-status-circle-green'></div>
                                <div>Bejegyzett</div>
                            </div>}

                            <div className='island-overview-info-container'>
                                <div className='island-overview-info-item'>Tagok: {island.acceptedMembers}</div>
                                <div className='island-overview-info-item'>Meghívottak: {island.invitedMembers}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>}
        </div>
    );
}

export default MyIslandsPage;