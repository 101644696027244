import { useEffect, useRef, useState } from 'react';
import './add-message-sender.styles.css';

import MessageService from '../../services/message.service';
import { ToastContainer, toast } from 'react-toastify';
import { authProvider } from '../../auth.provider';
import { useNavigate } from 'react-router-dom';
import Loader from '../loader/loader.component';

import ArrowLeftIcon from '../../icons/arrow-left.icon';
import ArrowRightIcon from '../../icons/arrow-right.icon';

import DefaultUserIcon from '../../assets/default-user-icon.jpg';

function AddMessageSenderDialog(props: any) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);

    const [senders, setSenders] = useState<any[]>([]);
    const [searchNameKeyword, setSearchNameKeyword] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const previousValues = useRef({ searchNameKeyword });
    const count = 5;

    useEffect(() => {
        fetchSenders();
    }, [page, searchNameKeyword]);

    /**
     * Closes the dialog.
     * @param _event the click event
     */
    function closeDialog(_event: any): void {
        props.onClose(null);
    }

    function fetchSenders(): void {
        setLoading(true);

        // If the keyword modified reset the page
        if (previousValues.current.searchNameKeyword != searchNameKeyword) {
            setPage(1);
        }

        MessageService.listSenders({ page, count, keyword: searchNameKeyword })
            .then((response: any) => {
                setSenders(response.data);
                setPageCount(response.headers.pageCount);
                setLoading(false);
            })
            .catch(err => {
                if (err.status) {
                    toast.error(err.status)
                } else {
                    if (err == 'A munkamenet lejárt!') {
                        toast.error(err);
                        setTimeout(() => {
                            authProvider.signout();
                            navigate('/login');
                        }, 2000);
                    }
                }
            });

        // Update previous values after checks
        previousValues.current = { searchNameKeyword };
    }

    /**
     * Handles the pagination by adding the index to the page.
     * @param index the index to add
     */
    function paginateTable(index: number): void {
        if (page + index < 1 || page + index > pageCount) {
            return;
        }
        setPage(page + index);
    }

    /**
     * Closes the dialog with the data
     * of the selected sender.
     */
    function selectSender(sender: any): void {
        props.onClose(sender);
    }

    return (<div id="add-message-sender-dialog">
        <div id="add-message-sender-dialog-content" className='fade-in-up light-shadow'>
            <h3 id='add-message-sender-title'>Feladó kiválasztása</h3>

            <div id="add-message-sender-form">
                <div id='sender-select-controls'>
                    <input onChange={(_event: any) => setSearchNameKeyword(_event.target.value)} className='add-message-sender-form-input' placeholder='Keresés név alapján' />
                    <div id='sender-select-pagination'>
                        <ArrowLeftIcon onClick={() => paginateTable(-1)} className='pagination-arrow-button' />
                        {page} / {pageCount}
                        <ArrowRightIcon onClick={() => paginateTable(1)} className='pagination-arrow-button' />
                    </div>
                </div>

                {loading && <div style={{ textAlign: 'center', marginTop: 24, marginBottom: 24 }}><Loader /></div>}

                {!loading && senders.map((sender: any) => (
                    <div key={sender._id} onClick={() => selectSender(sender)} className='sender-select-sender-container minimum-shadow'>
                        {sender.avatar == 'default' && <img src={DefaultUserIcon} className='sender-select-sender-image minimum-shadow' />}
                        {sender.avatar != 'default' && <img src={sender.avatar} className='sender-select-sender-image minimum-shadow' />}
                        <h3 className='sender-select-sender-name'>{sender.name}</h3>
                    </div>
                ))}

                <button onClick={closeDialog}>Bezárás</button>
            </div>
        </div>
    </div>);
}

export default AddMessageSenderDialog;