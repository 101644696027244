import { useEffect, useState } from 'react';
import './message-preview.styles.css';

import ImagePlaceholder from '../../../../../assets/image-placeholder.jpg';
import DefaultUserIcon from '../../../../../assets/default-user-icon.jpg';

import moment from 'moment';
import EyeIcon from '../../../../../icons/eye.icon';
import ChartIcon from '../../../../../icons/chart.icon';
import ArrowLeftIcon from '../../../../../icons/arrow-left.icon';
import { Fade } from 'react-slideshow-image';
import { BarChart } from '@mui/x-charts';

function MessagePreviewDialog(props: any) {

    const [loading, setLoading] = useState<boolean>(true);
    const [message, setMessage] = useState<any>(props.message);
    const [view, setView] = useState<string>('list');

    useEffect(() => {

        if (message.videoURL != '') {
            const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
            const match = message.videoURL.match(regex);
            const videoId = match ? match[1] : null;
            const thumbnail = videoId ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` : '';
            setMessage({ ...message, preview: { ...message.preview, thumbnail: { data: thumbnail } } });
        }

        setLoading(false);
    }, []);

    return (
        <div id='message-preview-container' className='fade-in-up'>

            <div id='message-preview-title-container' onClick={props.onClose}>
                <ArrowLeftIcon id='message-preview-back-button' />
                <h2 style={{ marginTop: 0 }} className='subpage-title'>Üzenet előnézete</h2>
            </div>

            <div id='message-preview-view-controls'>
                <select defaultValue={view} onChange={(_event: any) => setView(_event.target.value)} style={{ width: 100 }}>
                    <option value='list'>Lista nézet</option>
                    <option value='message'>Üzenet nézet</option>
                </select>
            </div>

            {view == 'list' && <p className='message-preview-view-description'>Az üzenet így fog megjelenni az üzenetek listájában!</p>}
            {view == 'message' && <p className='message-preview-view-description'>Az üzenet így fog megjelenni amikor valaki megnyitja!</p>}

            {!loading && view == 'list' && <div>
                <div className={'message-list-item minimum-shadow fade-in-up' + (message.priority == 1 ? ' message-list-item-priority' : '')}>
                    {!message.preview.thumbnail && <img className='message-list-item-default-thumbnail' src={ImagePlaceholder} />}
                    {message.preview.thumbnail && <img className='message-list-item-thumbnail' src={message.preview.thumbnail.data} />}

                    <div className='message-list-item-sender'>
                        {message.preview.sender.avatar == 'default' && <img src={DefaultUserIcon} className='message-list-item-sender-image' />}
                        {message.preview.sender.avatar != 'default' && <img src={message.preview.sender.avatar} className='message-list-item-sender-image' />}
                        <div>
                            <h4 className='message-list-item-sender-name'>{message.preview.sender.name}</h4>
                            <p className='message-list-item-created'>{moment(new Date()).locale('hu-hu').fromNow()}</p>
                        </div>
                    </div>

                    <div className='message-list-item-datas'>
                        <div className='message-list-item-data-container'>
                            <EyeIcon className='message-list-item-data-icon' />
                            <div className='message-list-item-data-text'>
                                0
                            </div>
                        </div>

                        {message.type == 'poll' && <div className='message-list-item-data-container'>
                            <ChartIcon className='message-list-item-data-icon' />
                        </div>}
                    </div>

                    <h3 className='message-list-item-title'>{message.title}</h3>

                    <div className='message-list-item-tags'>
                        {message.tags.map((tag: string) => (
                            <div key={tag}>{tag}</div>
                        ))}
                    </div>
                </div>
            </div>}

            {!loading && view == 'message' && <div style={{ overflow: 'scroll' }}>
                <div id='message-page-content' className='fade-in-up'>
                    {message.videoURL != '' && <iframe className='message-page-video light-shadow' width="100%" height="500" src={message.videoURL} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>}
                    {message.preview.images.length > 1 && <div className="slide-container">
                        <Fade cssClass='message-page-slideshow light-shadow' transitionDuration={300} autoplay={false}>
                            {message.preview.images.map((slideImage: any, index: number) => (
                                <div key={index}>
                                    <img className='message-page-image-item' src={slideImage.data} />
                                </div>
                            ))}
                        </Fade>
                    </div>}

                    {message.preview.images.length == 1 && <div className="slide-container">
                        <div className='message-page-standalone-image-slideshow light-shadow'>
                            <img className='message-page-image-item' src={message.preview.images[0].data} />
                        </div>
                    </div>}


                    <div id='message-page-sender-container'>
                        {message.sender.avatar == 'default' && <img src={DefaultUserIcon} id='message-page-sender-image' className='minimum-shadow' />}
                        {message.preview.sender.avatar != 'default' && <img src={message.preview.sender.avatar} id='message-page-sender-image' />}
                        <div>
                            <h4 id='message-page-sender-name'>{message.preview.sender.name}</h4>
                            <p id='message-page-created'>{new Date().toLocaleDateString()}</p>
                        </div>
                    </div>
                    <h1 id='message-page-title'>{message.title}</h1>

                    <div id='message-page-tag-list'>
                        {message.tags.map((tag: string) => (
                            <div key={tag} className='message-page-tag-item'>{tag}</div>
                        ))}
                    </div>

                    <p id='message-page-body'>{message.body}</p>

                    {message.type == 'poll' && <div id='message-page-poll-container' className='minimum-shadow'>
                        <h4 className='message-page-section-title'>Szavazás</h4>
                        <h5 id='message-page-poll-question'>{message.poll.question}</h5>
                        <p id='message-page-poll-description'>Választásod automatikusan elküldésre kerül,<br></br>de később módosíthatsz még rajta.</p>
                        <div id='message-page-chart'>
                            <div>
                                <div className='radio-group-vertical message-page-poll-radio-group minimum-shadow'>
                                    {message.poll.answers.map((answer: string) => (
                                        <div key={answer} className='radio-group-item'>
                                            <label className='radio-group-item-label'>
                                                <input value={answer} className='radio-group-item-button' type='radio' name='vote'></input>
                                                {answer}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <BarChart
                                xAxis={[
                                    {
                                        id: 'barCategories',
                                        data: message.poll.answers,
                                        scaleType: 'band',
                                        colorMap: {
                                            type: 'ordinal',
                                            colors: ['#081a38', '#fa3e4b', '#00ac6d', '#26a4ed', '#faf614', '#fa145d'],
                                        }
                                    },
                                ]}
                                series={[
                                    // { data: message.preview.poll.votesData.datas }
                                ]}
                                width={1000}
                                height={400}
                            />
                        </div>
                    </div>}
                </div>
            </div>}

        </div>
    );
}

export default MessagePreviewDialog;