import { useEffect, useState } from 'react';
import './server.styles.css';
import { toast } from 'react-toastify';

import ServerService from '../../../../services/server.service';
import Loader from '../../../../components/loader/loader.component';
import { authProvider } from '../../../../auth.provider';
import { useNavigate } from 'react-router-dom';

function ServerPage() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [systemConfig, setSystemConfig] = useState<any>(null);

    const [savingData, setSavingData] = useState<boolean>(false);

    useEffect(() => {
        getSystemConfig();
    }, []);

    /**
     * Fetches the system config from the server.
     */
    function getSystemConfig(): void {
        setLoading(true);

        ServerService.systemConfig()
            .then((response: any) => {
                setSystemConfig(response.data);
                setLoading(false);
            })
            .catch((err) => console.error(err));
    }

    function handleSystemConfigChange(property: string, newValue: any): void {
        systemConfig[property] = newValue;
        setSystemConfig(systemConfig);
    }

    function saveConfig(): void {
        setSavingData(true);

        ServerService.updateSystemConfig(systemConfig)
            .then(() => {
                setSavingData(false);
                toast.info('Sikeres mentés!')
            })
            .catch((err) => {
                setSavingData(false);
                
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    toast.error(err.status);
                    return;
                }
            });

    }

    return (
        <div className='subpage-window'>
            <h2 className='subpage-title'>Szerver beállítások</h2>

            {loading && !systemConfig && <div className='subpage-loader'>
                <Loader />
            </div>}

            {!loading && systemConfig && <div className='system-config-settings-panel fade-in-up'>

                <div className='checkbox-input-container'>
                    <input onChange={(_event: any) => handleSystemConfigChange('AUTO_REG', _event.target.checked)} defaultChecked={systemConfig.AUTO_REG} className='checkbox-input' type='checkbox' />
                    <div className='checkbox-input-text'>Felhasználói regisztrációk automatikus elfogadása</div>
                </div>

                <div className='checkbox-input-container'>
                    <input onChange={(_event: any) => handleSystemConfigChange('AUTO_ISLAND_REG', _event.target.checked)} defaultChecked={systemConfig.AUTO_ISLAND_REG} className='checkbox-input' type='checkbox' />
                    <div className='checkbox-input-text'>Sziget alapítások automatikus elfogadása</div>
                </div>

                <div className='form-item'>
                    <label className='form-item-label'>Sziget létrehozás maximum</label>
                    <input min={1} type='number' onChange={(_event: any) => handleSystemConfigChange('MAXIMUM_ISLAND_CREATE_PER_USER', Number(_event.target.value))} defaultValue={systemConfig.MAXIMUM_ISLAND_CREATE_PER_USER} className='island-registration-input' placeholder='Ennyi szigetet hozhat létre maximum egy felhasználó' />
                </div>

                <div className='form-item'>
                    <label className='form-item-label'>Szigethez csatlakozás maximum</label>
                    <input min={1} type='number' onChange={(_event: any) => handleSystemConfigChange('MAXIMUM_ISLAND_JOIN_PER_USER', Number(_event.target.value))} defaultValue={systemConfig.MAXIMUM_ISLAND_JOIN_PER_USER} className='island-registration-input' placeholder='Ennyi szigethez csatlakozhat maximum egy felhasználó' />
                </div>

                <div className='form-item'>
                    <label className='form-item-label'>Sziget bejegyzéséhez szükséges tagszám</label>
                    <input min={1} type='number' onChange={(_event: any) => handleSystemConfigChange('MINIMUM_ISLAND_MEMBER_REQUIRED', Number(_event.target.value))} defaultValue={systemConfig.MINIMUM_ISLAND_MEMBER_REQUIRED} className='island-registration-input' placeholder='Ennyi tagnak csatlakoznia kell, hogy a sziget bejegyzésre kerüljön' />
                </div>

                <button disabled={savingData} onClick={saveConfig} className='primary-button system-config-save-button minimum-shadow'>Módosítások mentése</button>
            </div>}
        </div>
    );
}

export default ServerPage;