 
/**
 * Fetches the server system config settings.
 * @returns the server system config settings
 */
async function systemConfig(): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/server/system-config`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`
                }
            });

            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Updates the server SYSTEM_CONFIG.
 * @param payload The payload to send
 * @returns OK if the action was successful
 */
async function updateSystemConfig(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/server/system-config/update`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

export default {
    systemConfig,
    updateSystemConfig
};