
/**
 * Fetches the user list from the API server with
 * the given filters in the payload.
 * @param payload the payload to send
 * @returns on success it returns the users list
 */
async function list(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/user/list?page=${payload.page}&count=${payload.count}&activated=${payload.activated}&keyword=${payload.keyword}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`
                }
            });
            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    const errorResponse = await response.json();
                    return reject(errorResponse);
                }
            }

            const pageCount = response.headers.get('X-Page-Count');

            const data = await response.json();
            resolve({ headers: { pageCount }, data: data.data});
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Activates the pending user registration's user.
 * @param payload the payload to send
 * @returns OK if the action was successful
 */
async function activate(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/user/activate`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    const errorResponse = await response.json();
                    return reject(errorResponse);
                }
            }

            const pageCount = response.headers.get('X-Page-Count');

            const data = await response.json();
            resolve({ headers: { pageCount }, data: data.data});
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Declines the pending user registration.
 * @param payload the payload to send
 * @returns the response
 */
async function decline(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/user/decline`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    const errorResponse = await response.json();
                    return reject(errorResponse);
                }
            }

            const pageCount = response.headers.get('X-Page-Count');

            const data = await response.json();
            resolve({ headers: { pageCount }, data: data.data});
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Fetches the current logged in user's profile.
 * @returns the response
 */
async function profile(): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/user/profile`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`
                }
            });

            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    const errorResponse = await response.json();
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data});
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Updates the user avatar and removes the old one.
 * @param avatar the avatar file to send.
 * @returns the response
 */
async function updateAvatar(avatar: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {

            const formData = new FormData();
            formData.append('avatar', avatar);

            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/user/update/avatar`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${sessionToken}`
                }
            });

            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

export default {
    list,
    activate,
    decline,
    profile,
    updateAvatar
};