import { useEffect, useRef, useState } from 'react';
import './select-island.styles.css';

import { toast } from 'react-toastify';
import Loader from '../loader/loader.component';
import ArrowLeftIcon from '../../icons/arrow-left.icon';
import ArrowRightIcon from '../../icons/arrow-right.icon';

import { authProvider } from '../../auth.provider';
import { useNavigate } from 'react-router-dom';
import IslandService from '../../services/island.service';

function SelectIslandDialog(props: any) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);

    const [islands, setIslands] = useState<any[]>([]);
    const [searchNameKeyword, setSearchNameKeyword] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const previousValues = useRef({ searchNameKeyword });
    const count = 5;

    useEffect(() => {
        // If the keyword or the activated modified reset the page
        if (previousValues.current.searchNameKeyword != searchNameKeyword) {
            setPage(1);
        }

        listAllIslands();

        // Update previous values after checks
        previousValues.current = { searchNameKeyword };
    }, [page, searchNameKeyword]);

    /**
     * Lists all the islands with pagination
     * and filters.
     */
    function listAllIslands(): void {
        IslandService.listAll({ page, count, keyword: searchNameKeyword, status: 'all' })
            .then((response: any) => {
                setIslands(response.data);
                setPageCount(response.headers.pageCount);
                setLoading(false);
            })
            .catch((err) => {
                if (err == 'A munkamenet lejárt!') {
                    toast.error(err);
                    setTimeout(() => {
                        authProvider.signout();
                        navigate('/login');
                    }, 2000);
                    return;
                }

                if (err.error) {
                    toast.error(err.error);
                    return;
                }

                if (err.status) {
                    toast.error(err.status);
                    return;
                }
            });
    }

    /**
     * Handles the pagination by adding the index to the page.
     * @param index the index to add
     */
    function paginateTable(index: number): void {
        if (page + index < 1 || page + index > pageCount) {
            return;
        }
        setPage(page + index);
    }

    /**
     * Closes the dialog.
     * @param _event the click event
     */
    function closeDialog(_event: any): void {
        props.onClose(null);
    }

    /**
     * Calles the on close function of the component
     * to return the selected island to the parent.
     * @param island the selected island
     */
    function selectIsland(island: any): void {
        props.onClose(island);
    }

    return (
        <div id="select-settlement-sender-dialog">
            <div id="select-settlement-sender-dialog-content" className='fade-in-up light-shadow'>
                <h3 id='select-settlement-sender-title'>Sziget kiválasztása</h3>

                <div id="select-settlement-sender-form">
                    <div id='sender-select-controls'>
                        <input onChange={(_event: any) => setSearchNameKeyword(_event.target.value)} className='select-settlement-sender-form-input' placeholder='Keresés név alapján' />
                        <div id='sender-select-pagination'>
                            <ArrowLeftIcon onClick={() => paginateTable(-1)} className='pagination-arrow-button' />
                            {page} / {pageCount}
                            <ArrowRightIcon onClick={() => paginateTable(1)} className='pagination-arrow-button' />
                        </div>
                    </div>

                    {loading && <div style={{ textAlign: 'center', marginTop: 24, marginBottom: 24 }}><Loader /></div>}

                    {!loading && islands.map((island: any) => (
                        <div onClick={() => selectIsland(island)} key={island._id} className='island-select-island-container minimum-shadow'>
                            <img className='island-select-island-logo minimum-shadow' src={island.logo} />
                            <div>
                                <h3 className='island-select-island-name'>{island.name}</h3>
                                <p className='island-select-island-address'>{island.county}, {island.city} {island.address}</p>
                            </div>
                        </div>
                    ))}

                    <button onClick={closeDialog}>Bezárás</button>
                </div>
            </div>
        </div>
    );
}

export default SelectIslandDialog;