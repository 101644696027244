/**
 * Fetches the user's island data.
 * @returns the island data or error
 */
async function list(): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/island/list`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                }
            });
            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Fetches the island data by the given id.
 * @param islandId the id of the island
 * @returns the island data if the user has permission to read it.
 */
async function get(islandId: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/island/get?island=${islandId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                }
            });
            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Sends an Island creation request to the API.
 * @param payload the payload to send
 * @returns OK if the request was successful
 */
async function create(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {

            const formData = new FormData();
            formData.append('logo', payload.logo);
            formData.append('name', payload.name);
            formData.append('county', payload.county);
            formData.append('city', payload.city);
            formData.append('address', payload.address);
            formData.append('introduction', payload.introduction);
            formData.append('members', JSON.stringify(payload.members));

            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/island/create`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${sessionToken}`
                }
            });

            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Fetches the island members paginated.
 * @param payload the payload of the request
 * @returns the island members in a paginated array
 */
async function members(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/island/members?island=${payload.islandId}&accepted=${payload.accepted}&keyword=${payload.keyword}&page=${payload.page}&count=${payload.count}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                }
            });
            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const pageCount = response.headers.get('X-Page-Count');

            const data = await response.json();
            resolve({ headers: { pageCount }, data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Removes the given member from the island.
 * It also deletes the invitation if it exists.
 * @param payload the payload to send
 * @returns OK if the action was successful
 */
async function removeMember(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/island/member/remove`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Refreshes the island member invitation email.
 * @param payload the payload to send
 * @returns OK if the action was successful
 */
async function refreshInvitation(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/island/member/refresh-invite`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Sends a new member invitation request to the API.
 * @param payload the payload to send
 * @returns OK if the action was successful.
 */
async function invite(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/island/member/invite`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }
            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Sends the island data to the API server for update.
 * If the logo is presented it will
 * be updated as well otherwise it will be the same.
 * @param payload the payload for the request
 * @param logo the logo for the island (also could be null)
 * @returns OK if the action was successful.
 */
async function update(payload: any, logo: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {

            const formData = new FormData();

            if (logo) {
                formData.append('logo', logo);
            }

            formData.append('islandId', payload._id);
            formData.append('name', payload.name);
            formData.append('county', payload.county);
            formData.append('city', payload.city);
            formData.append('address', payload.address);
            formData.append('introduction', payload.introduction);
            formData.append('members', JSON.stringify(payload.members));

            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/island/update`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${sessionToken}`
                }
            });

            if (!response.ok) {
                if (response.status == 401) {
                    // Session key expired
                    return reject('A munkamenet lejárt!');
                } else {
                    let errorResponse = await response.json();
                    errorResponse['code'] = response.status;
                    return reject(errorResponse);
                }
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Sends the invitation code to the backend server
 * to accept the invitation.
 * @param code the invitation code
 * @returns OK if the action was successful
 */
async function memberJoin(code: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(`/api/v1/island/member/join?code=${code}`, {
                method: 'GET'
            });

            if (!response.ok) {
                let errorResponse = await response.json();
                errorResponse['code'] = response.status;
                return reject(errorResponse);
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Lists all of the islands.
 * @param payload the payload to send  
 * @returns the island list with pagination.
 */
async function listAll(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/island/list-all?page=${payload.page}&count=${payload.count}&status=${payload.status}&keyword=${payload.keyword}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`
                }
            });

            if (!response.ok) {
                let errorResponse = await response.json();
                errorResponse['code'] = response.status;
                return reject(errorResponse);
            }

            const pageCount = response.headers.get('X-Page-Count');

            const data = await response.json();
            resolve({ headers: { pageCount }, data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

/**
 * Sends an island status update to the API server.
 * @param payload the payload to send
 * @returns OK if the action was successful
 */
async function updateStatus(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/island/status`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Authorization': `Bearer ${sessionToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                let errorResponse = await response.json();
                errorResponse['code'] = response.status;
                return reject(errorResponse);
            }

            const data = await response.json();
            resolve({ data: data.data });
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}


export default {
    list,
    get,
    create,
    members,
    removeMember,
    refreshInvitation,
    invite,
    update,
    memberJoin,
    listAll,
    updateStatus
}