import { useState } from 'react';
import './registration.styles.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TiszaLogo from '../../assets/tiszalogo.png';

import AuthenticationService from '../../services/authentication.service';

function RegistrationPage() {

    const [emailCodeSent, setEmailCodeSent] = useState<boolean>(false);

    const navigate = useNavigate();

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [telephone, setTelephone] = useState<string>('');
    const [birth, setBirth] = useState<Date>(new Date());
    const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
    const [emailCode, setEmailCode] = useState<string>('');

    const [validationPending, setValidationPending] = useState<boolean>(false);
    const [registrationPending, setRegistrationPending] = useState<boolean>(false);

    /**
     * Sends the registration code to the
     * user's email address.
     */
    function sendCode(): void {
        setValidationPending(true);
        // Validate the form data
        if (!email) {
            setValidationPending(false);
            toast.error("Az e-mail cím megadása kötelező!");
            return;
        }

        AuthenticationService.registrationOtp({ email })
            .then(() => {
                setValidationPending(false);
                setEmailCodeSent(true);
            })
            .catch(err => {
                setValidationPending(false);
                toast.error(err.status)
            });
    }

    /**
     * Sends the registration form payload into the server API.
     * To register the user into the database.
     */
    function register(): void {
        setRegistrationPending(true);

        // Validate the form data
        if (!name || !email || !telephone || !birth || !emailCode || privacyPolicy === false) {
            setRegistrationPending(false);
            toast.error("Az űrlap nem lett megfelelően kitöltve!")
            return;
        }

        const payload: any = {
            name,
            email,
            telephone,
            birth,
            code: emailCode
        }

        AuthenticationService.registration(payload)
            .then(() => {
                setRegistrationPending(false);
                navigate('/login');
            })
            .catch(err => {
                setRegistrationPending(false);
                toast.error(err.status)
            });
    }

    return <div id='registration-window'>
        <div id='registration-window-overlay'>
            <img id="registration-panel-logo" src={TiszaLogo} />
            <div id='registration-panel' className='light-shadow fade-in-up'>
                <div id="registration-panel-header">
                    <h3 id="registration-panel-title">Regisztráció</h3>
                </div>
                <div id="registration-panel-form">
                    {!emailCodeSent && <p className='registration-panel-description'>Először add meg az email címed, melyre egy regisztrációs kódot fogunk küldeni!</p>}
                    {emailCodeSent && <p className='registration-panel-description'>Add meg az adataid a regisztráció befejezéséhez.</p>}
                    {!emailCodeSent && <input disabled={emailCodeSent} onKeyUp={(_event: any) => setEmail(_event.target.value)} name='email' className='registration-panel-input' placeholder='E-mail cím' />}
                    {emailCodeSent && <>
                        <input onKeyUp={(_event: any) => setName(_event.target.value)} name='name' className='registration-panel-input' placeholder='Teljes név' />
                        <input onKeyUp={(_event: any) => setTelephone(_event.target.value)} name='telephone' className='registration-panel-input' placeholder='Telefonszám' />
                        <input onChange={(_event: any) => setBirth(_event.target.value)} name='birth' type="date" className='registration-panel-input' placeholder='Születési dátum' />
                        <input onKeyUp={(_event: any) => setEmailCode(_event.target.value)} name='code' className='registration-panel-input' placeholder='Regisztrációs kód' />
                        <div className='checkbox-input-container'>
                            <input onClick={(_event: any) => setPrivacyPolicy(_event.target.checked)} className='checkbox-input' type="checkbox" /><div className='checkbox-input-text'>Adatkezelési tájékoztató</div>
                        </div>
                    </>}

                    {!emailCodeSent && <button disabled={validationPending} onClick={sendCode} className='registration-panel-button primary-button minimum-shadow'>Kód küldése</button>}

                    {emailCodeSent && <button disabled={registrationPending} onClick={register} className='registration-panel-button primary-button minimum-shadow'>Regisztráció</button>}
                    <div>
                        <p onClick={() => navigate('/login')} id="login-registration-text">Ugrás a bejelentkezéshez!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default RegistrationPage;