import { useEffect, useRef, useState } from 'react';
import './messages.styles.css';
import { toast } from 'react-toastify';

import MessageService from '../../../../services/message.service';
import { authProvider } from '../../../../auth.provider';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../components/loader/loader.component';

import ImagePlaceholder from '../../../../assets/image-placeholder.jpg';
import EyeIcon from '../../../../icons/eye.icon';

import millify from "millify";
import ChartIcon from '../../../../icons/chart.icon';

import DefaultUserIcon from '../../../../assets/default-user-icon.jpg';

import moment from 'moment';
import ArrowLeftIcon from '../../../../icons/arrow-left.icon';
import ArrowRightIcon from '../../../../icons/arrow-right.icon';
import SelectTagDialog from '../../../../components/select-tag/select-tag.dialog';

function MessagesPage() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [messages, setMessages] = useState<Array<any>>([]);
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [messageSearchTags, setMessageSearchTags] = useState<Set<string>>(new Set());
    const previousValues = useRef({ messageSearchTags });
    const count = 50;

    const [showTagSelectDialog, setShowTagSelectDialog] = useState<boolean>(false);

    useEffect(() => {
        listMessages();
    }, [page, messageSearchTags]);

    /**
     * Handles the pagination by adding the index to the page.
     * @param index the index to add
     */
    function paginateTable(index: number): void {
        if (page + index < 1 || page + index > pageCount) {
            return;
        }
        setPage(page + index);
    }

    /**
     * Lists the user's messages.
     */
    function listMessages(): void {
        setLoading(true);

        // If the keyword or the activated modified reset the page
        if (previousValues.current.messageSearchTags != messageSearchTags) {
            setPage(1);
        }

        MessageService.list({ page, count, tagsFilter: Array.from(messageSearchTags).join(',') })
            .then(async response => {
                // Fetch the image thumbnails from the server
                const messagesWithThumbnails = await Promise.all(
                    response.data.map(async (message: any) => {
                        // Generate the thumbnail from the attached images
                        if (message.images.length > 0) {
                            const thumbnail = await fetchImage(message.images[0]);
                            return { ...message, thumbnail };
                        }

                        // Generate the thumbnail from the video
                        if (message.videoURL != '') {
                            const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
                            const match = message.videoURL.match(regex);
                            const videoId = match ? match[1] : null;
                            const thumbnail = videoId ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` : '';
                            return { ...message, thumbnail };
                        }

                        return message;
                    })
                );

                setMessages(messagesWithThumbnails);
                setPageCount(response.headers.pageCount);
                setLoading(false);
            })
            .catch(err => {
                if (err.status) {
                    toast.error(err.status)
                } else {
                    if (err == 'A munkamenet lejárt!') {
                        toast.error(err);
                        setTimeout(() => {
                            authProvider.signout();
                            navigate('/login');
                        }, 2000);
                    }

                    if (err.error) {
                        toast.error(err.error);
                        return;
                    }

                    if (err.status) {
                        toast.error(err.status);
                        return;
                    }
                }
            });

        // Update previous values after checks
        previousValues.current = { messageSearchTags };
    }

    /**
     * Fetches the restricted image from the server.
     * @param imageURL the url of the image
     * @returns 
     */
    async function fetchImage(imageURL: string) {
        try {
            const sessionToken = localStorage.getItem('SESSION_KEY');
            const response = await fetch(imageURL, {
                headers: {
                    Authorization: `Bearer ${sessionToken}`,
                }
            });
            if (!response.ok) throw new Error('Failed to fetch image');

            const blob = await response.blob();
            return (URL.createObjectURL(blob));
        } catch (error) {
            toast.error('Nem sikerült letölteni a képet!');
        }
    }

    /**
     * Navigates the message page which will load and shows
     * the message content.
     * @param messageId the id of the message to open
     */
    function openMessage(messageId: string): void {
        navigate(`/home/message/${messageId}`);
    }

    /**
     * Called when the tag select dialog closed.
     * @param tag the selected tag or null if the dialog closed
     */
    function tagSelectDialogClosed(tag: string | null): void {
        setShowTagSelectDialog(false);
        if (tag) {
            setMessageSearchTags(prevTags => new Set(Array.from(prevTags).concat(tag)));
        }
    }

    function removeMessageSearchTag(_event: any, tag: string): void {
        _event.stopPropagation();
        const newTags = new Set(messageSearchTags);
        newTags.delete(tag);
        setMessageSearchTags(newTags);
    }

    return (
        <div className='subpage-window'>
            {showTagSelectDialog && <SelectTagDialog onClose={tagSelectDialogClosed} />}

            <h2 className="subpage-title">Üzenetek</h2>

            <div id='messages-controls'>
                <div id='messages-tag-search-bar' onClick={() => setShowTagSelectDialog(true)}>
                    {messageSearchTags?.size < 1 && <div id='message-tag-search-bar-no-tags'>Kattints a címkékre való szűrésért</div>}
                    {Array.from(messageSearchTags).map((tag: string) => (
                        <div onClick={(_event: any) => removeMessageSearchTag(_event, tag)} className='message-tag-search-bar-tag' key={tag}>
                            {tag}
                        </div>
                    ))}
                </div>
                <div id='messages-pagination'>
                    <ArrowLeftIcon onClick={() => paginateTable(-1)} className='pagination-arrow-button' />
                    {page} / {pageCount}
                    <ArrowRightIcon onClick={() => paginateTable(1)} className='pagination-arrow-button' />
                </div>
            </div>

            {loading && <div className='subpage-loader'>
                <Loader />
            </div>}

            {!loading && messages.length < 1 && <p className='messages-no-data fade-in-up'>Nincs megjeleníthető üzenet!</p>}

            {!loading && messages.length > 0 && <div className='message-list fade-in-up'>
                {messages.map((message: any) => (
                    <div onClick={() => openMessage(message._id)} key={message._id} className={'message-list-item minimum-shadow ' + (message.priority == 1 ? 'message-list-item-priority' : '')}>
                        {!message.thumbnail && <img className='message-list-item-default-thumbnail' src={ImagePlaceholder} />}
                        {message.thumbnail && <img className='message-list-item-thumbnail' src={message.thumbnail} />}

                        <div className='message-list-item-sender'>
                            {message.sender.avatar == 'default' && <img src={DefaultUserIcon} className='message-list-item-sender-image' />}
                            {message.sender.avatar != 'default' && <img src={message.sender.avatar} className='message-list-item-sender-image' />}
                            <div>
                                <h4 className='message-list-item-sender-name'>{message.sender.name}</h4>
                                <p className='message-list-item-created'>{moment(new Date(message.created)).locale('hu-hu').fromNow()}</p>
                            </div>
                        </div>

                        <div className='message-list-item-datas'>
                            <div className='message-list-item-data-container'>
                                <EyeIcon className='message-list-item-data-icon' />
                                <div className='message-list-item-data-text'>
                                    {millify(message.seenCount)}
                                </div>
                            </div>

                            {message.type == 'poll' && <div className='message-list-item-data-container'>
                                <ChartIcon className='message-list-item-data-icon' />
                            </div>}
                        </div>

                        <h3 className='message-list-item-title'>{message.title}</h3>

                        <div className='message-list-item-tags'>
                            {message.tags.map((tag: string) => (
                                <div key={tag}>{tag}</div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>}
        </div>
    );
}

export default MessagesPage;