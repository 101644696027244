import { useEffect, useRef, useState } from 'react';
import './select-settlement.styles.css';

import { toast } from 'react-toastify';
import Loader from '../loader/loader.component';
import ArrowLeftIcon from '../../icons/arrow-left.icon';
import ArrowRightIcon from '../../icons/arrow-right.icon';

import SettlementService from '../../services/settlement.service';
import { authProvider } from '../../auth.provider';
import { useNavigate } from 'react-router-dom';

function SelectSettlementDialog(props: any) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);

    const [settlements, setSettlements] = useState<any[]>([]);
    const [searchNameKeyword, setSearchNameKeyword] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const previousValues = useRef({ searchNameKeyword });
    const count = 5;

    useEffect(() => {
        fetchSettlements();
    }, [page, searchNameKeyword]);

    function fetchSettlements(): void {
        // If the keyword modified reset the page
        if (previousValues.current.searchNameKeyword != searchNameKeyword) {
            setPage(1);
        }

        SettlementService.list({ page, count, keyword: searchNameKeyword })
            .then((response: any) => {
                setSettlements(response.data);
                setPageCount(response.headers.pageCount);
                setLoading(false);
            })
            .catch(err => {
                if (err.status) {
                    toast.error(err.status)
                } else {
                    if (err == 'A munkamenet lejárt!') {
                        toast.error(err);
                        setTimeout(() => {
                            authProvider.signout();
                            navigate('/login');
                        }, 2000);
                    }
                }
            });

        // Update previous values after checks
        previousValues.current = { searchNameKeyword };
    }

    /**
     * Closes the dialog.
     * @param _event the click event
     */
    function closeDialog(_event: any): void {
        props.onClose(null);
    }

    /**
     * Handles the pagination by adding the index to the page.
     * @param index the index to add
     */
    function paginateTable(index: number): void {
        if (page + index < 1 || page + index > pageCount) {
            return;
        }
        setPage(page + index);
    }

    /**
     * Returns the selected settlement to the parent component.
     * @param settlement the selected settlement
     */
    function selectSettlement(settlement: string): void {
        props.onClose(settlement);
    }

    return (<div id="select-settlement-sender-dialog">
        <div id="select-settlement-sender-dialog-content" className='fade-in-up light-shadow'>
            <h3 id='select-settlement-sender-title'>Teleülés kiválasztása</h3>

            <div id="select-settlement-sender-form">
                <div id='sender-select-controls'>
                    <input onChange={(_event: any) => setSearchNameKeyword(_event.target.value)} className='select-settlement-sender-form-input' placeholder='Keresés név alapján' />
                    <div id='sender-select-pagination'>
                        <ArrowLeftIcon onClick={() => paginateTable(-1)} className='pagination-arrow-button' />
                        {page} / {pageCount}
                        <ArrowRightIcon onClick={() => paginateTable(1)} className='pagination-arrow-button' />
                    </div>
                </div>

                {loading && <div style={{ textAlign: 'center', marginTop: 24, marginBottom: 24 }}><Loader /></div>}

                {!loading && settlements.map((settlement: any) => (
                    <div key={settlement._id} onClick={() => selectSettlement(settlement.name)} className='sender-select-sender-container minimum-shadow'>
                        <h3 className='sender-select-sender-name'>{settlement.name}</h3>
                    </div>
                ))}

                <button onClick={closeDialog}>Bezárás</button>
            </div>
        </div>
    </div>);
}

export default SelectSettlementDialog;